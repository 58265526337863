import React from 'react'
import {useSelector} from 'react-redux'

import {ageFilterForCint} from '../RunUtils'

const CintQuotaOverview = () => {
  const cintQuotas = useSelector(state => state.runFormOverview?.cint_quotas) || []
  const interlockedQuotas = useSelector(state => state.runFormOverview?.cint_interlocked_quotas) || []

  const getClassName = (quotaId) => {
    return interlockedQuotas.includes(String(quotaId))
      ? 'fas fa-link cint-overview-icon interlock-icon'
      : 'fas fa-chart-pie cint-overview-icon'
  }

  if (!cintQuotas.length) return null

  return (
    <>
      {cintQuotas.map(quota => (
        quota.id === ageFilterForCint.id ? (
          <div key={quota.id}>
            <div className="table_wrap my-3">
              <span className="run-form-overview-heading">
                {quota.name}
                <i className={getClassName(quota.id)}></i>
              </span>
              <span>
                {quota.from} - {quota.to}
              </span>
            </div>
          </div>
        ) : (
          <div className="mt-3" key={quota.id}>
            <div className="table_wrap">
              <span className="heading">
                {quota.name}
                <i className={getClassName(quota.id)}></i>
              </span>
            </div>
            {quota.options?.map(option =>
              option.participants ? (
                <div key={option.id}>
                  <div className="table_wrap mb-1">
                    <span/>
                    <span className="success-tick filter-list">
                    {option.name}
                  </span>
                  </div>
                </div>
              ) : null
            )}
          </div>
        )
      ))}
    </>
  )
}

export default CintQuotaOverview
