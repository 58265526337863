import React from 'react'
import {Link} from 'react-router-dom'
import {getMenuItemClass} from './menu-items-utils'

const ContactUsMenuItem = () => {
  return (
    <li>
      <Link
        to="/users/contact-us"
        className={getMenuItemClass('contact')}
      >
        Contact Us
      </Link>
    </li>
  )
}

export default ContactUsMenuItem;
