import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import * as Yup from 'yup'
import {useFormik} from 'formik'

import ScreeningSetupPopup from './ScreeningSetupPopup'
import {runFormOverviewUpdate} from '../../../redux/actions'
import {removeHighlight} from './RunUtils'
import {GA_EVENTS, navigateToDetailsTab, sendGAEvent} from '../../../utils/helper'

const $ = window.$

const CintScreening = (
  {
    minIncidenceRate,
    maxIncidenceRate,
    setScreeninRate,
    showBudgetChangedWarning,
    setShowBudgetChangedWarning,
    setActiveTab,
    cintScreenerFormRef
  }) => {
  const dispatch = useDispatch()
  const [isScreeningFormValid, setIsScreeningFormValid] = useState(false)

  const {
    isQuoteLoading,
    agreeScreeningGuidelines,
    estScreeningDuration,
    estScreeningDurationType,
    estIncidenceRate,
    estMaxBudget,
    quote,
    isDetailsFormValid,
    positly_user_id,
    platform
  } = useSelector(state => ({
    isQuoteLoading: state.runForm.quote?.isLoading,
    numberOfSubmissions: state.runFormOverview.task_parameters.number_of_submissions,
    payPerSubmission: state.runFormOverview.task_parameters.payment_per_submission,
    agreeScreeningGuidelines: state.runForm.agree_screening_guidelines,
    estScreeningDuration: state.runForm.task_parameters.est_screening_duration,
    estScreeningDurationType: state.runForm.task_parameters.est_screening_duration_type,
    estIncidenceRate: state.runForm.task_parameters.est_incidence_rate,
    estMaxBudget: state.runForm.task_parameters.est_max_budget,
    quote: state.runForm.quote,
    isDetailsFormValid: state.runFormOverview.isDetailsFormValid,
    positly_user_id: state.currentUser?.uid,
    platform: state.runFormOverview?.platform?.full_name
  }))

  const initialValues = {
    agree_screening_guidelines: agreeScreeningGuidelines || false,
    task_parameters: {
      est_screening_duration: estScreeningDuration || 0,
      est_screening_duration_type: estScreeningDurationType || 'minutes',
      est_incidence_rate: estIncidenceRate || 0,
      est_max_budget: estMaxBudget || 0,
    }
  }

  const validations = {
    task_parameters: Yup.object().shape({
      est_screening_duration: Yup.number()
        .required('Estimated screening duration is required')
        .when('est_screening_duration_type', {
          is: (value) => value === 'seconds',
          then: (schema) => schema
            .min(30, 'Minimum screening duration is 30 seconds')
            .max(180, 'Your screening questions can’t take longer than 3 minutes'),
        })
        .when('est_screening_duration_type', {
          is: (value) => value === 'minutes',
          then: (schema) => schema
            .min(0.5, 'Minimum screening duration is 30 seconds')
            .max(3, 'Your screening questions can’t take longer than 3 minutes'),
        }),
      est_screening_duration_type: Yup.string()
        .required('Estimated screening duration type is required'),
      est_incidence_rate: Yup.number()
        .required('Estimated eligibility rate is required')
        .min(minIncidenceRate, `Estimated eligibility rate cannot be less than ${minIncidenceRate}%`)
        .max(maxIncidenceRate, `Estimated eligibility rate cannot exceed ${maxIncidenceRate}%`),
      est_max_budget: Yup.number()
        .required('Max budget is required')
        .test({
          name: 'testMaxBudget',
          message: `Max Budget cannot be less than $${quote?.defaultMaxBudget}`,
          test: (value) => (quote?.defaultMaxBudget ? value >= quote.defaultMaxBudget : true),
        }),
    }),
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object(validations),
  })

  const openScreeningSetupPopUp = (e) => {
    e.preventDefault()

    if (!isDetailsFormValid) {
      return navigateToDetailsTab(setActiveTab)
    }

    sendGAEvent(GA_EVENTS.CLICK_SETUP_SCREENING_ACTIVITY, {positly_user_id, platform})
    $.magnificPopup.open({
      items: {src: `#cint-screening`},
      type: 'inline',
      modal: true,
      closeOnBgClick: false
    })
  }

  const validateAndDispatchForm = (formValues) => {
    if (!formik.values.agree_screening_guidelines) {
      const values = {
        agree_screening_guidelines: false,
        task_parameters: {
          est_screening_duration: undefined,
          est_screening_duration_type: undefined,
          est_incidence_rate: undefined,
          est_max_budget: undefined,
        }
      }

      setIsScreeningFormValid(true)
      return dispatch(runFormOverviewUpdate({...values, isScreeningFormValid: true}))
    }

    formik.validateForm().then((data) => {
      const isScreeningFormValid = data && !Object.keys(data).length
      const values = formValues ? formValues : formik.values

      setIsScreeningFormValid(isScreeningFormValid)
      dispatch(runFormOverviewUpdate({...values, isScreeningFormValid}))
    })
  }

  useEffect(() => {
    if (!formik.values.agree_screening_guidelines)
      setShowBudgetChangedWarning(false)

    validateAndDispatchForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.agree_screening_guidelines])


  useEffect(() => {
    if (estIncidenceRate) setScreeninRate(estIncidenceRate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estIncidenceRate])

  useEffect(() => {
    if (!quote?.totalRunCost) return

    formik.setFieldValue('task_parameters.est_max_budget', quote.defaultMaxBudget)
    dispatch(runFormOverviewUpdate({costEstimate: quote.totalRunCost}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote?.totalRunCost])

  if (formik.values.agree_screening_guidelines && isScreeningFormValid && !showBudgetChangedWarning)
    removeHighlight('screening-setup')

  return (
    <>
      <div className="box" ref={cintScreenerFormRef}>
        <h2 className="font-size-15 mb-2">
          If you don't find the filters you need, add a Screen-Out Link to limit who participates (optional)
        </h2>

        <div className="wrap_info_form help-text">
          <span>
            While filters are the simplest way to limit who participates (see the filter section above),
            you can also send participants that don't meet whatever criteria you choose (based on custom questions
            that you add to your activity) to a Screen-Out Link to prevent their participation in your study.
          </span>
        </div>

        <div className="d-flex justify-content-center p-2 mt-3" id="screening-setup">
          <Link
            className="link"
            to=""
            tabIndex="4"
            onClick={openScreeningSetupPopUp}
          >
            Set up Screening
          </Link>
          {formik.values.agree_screening_guidelines && isScreeningFormValid && !showBudgetChangedWarning
            ? <label className="screening-setup success-tick-filled">&nbsp;</label>
            : null
          }
        </div>

        <div>
          {formik.values.agree_screening_guidelines && showBudgetChangedWarning && quote.totalRunCost ? (
            <p className="po-text text-red-soft text-center mb-2">
              Your cost estimate has changed. Please update your screening setup.
            </p>
          ) : null}
        </div>

        <ScreeningSetupPopup
          formik={formik}
          costEstimate={quote?.totalRunCost}
          setScreeninRate={setScreeninRate}
          validateAndDispatchForm={validateAndDispatchForm}
          isQuoteLoading={isQuoteLoading}
          setShowBudgetChangedWarning={setShowBudgetChangedWarning}
        />
      </div>
    </>
  )
}

export default CintScreening
