import React, {useEffect, useRef, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import qs from 'qs'

// 3rd party
import Select from 'react-select'

// Redux actions
import {preScreenProjectsFetch, preScreenRunsFetch} from '../../../redux/actions'

const PreviousParticipationFilter = (props) => {
  // Query params
  const history = useHistory()
  const queryParams = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  })
  const runId = queryParams.run_id || queryParams.id

  const getPsType = () => {
    if (props.runsCount === 0)
      return 'does_not_matter'
    else if ((props.prescreenData.ps_type === 'does_not_matter' && !props.isRunUpdate) ||
      (props.prescreenData.ps_type === 'custom' && !props.isMturk))
      return ''
    else
      return props.prescreenData.ps_type
  }

  // Variables
  const [prescreenType, setPrescreenType] = useState(getPsType() || '')
  const [prescreenMode, setPrescreenMode] = useState(props.prescreenData.ps_mode || '')
  const [prescreenStatus, setPrescreenStatus] = useState(props.prescreenData.ps_status || '')
  const [prescreenComparator, setPrescreenComparator] = useState(props.prescreenData.ps_comparator || '')
  const [prescreenLevel, setPrescreenLevel] = useState(props.prescreenData.ps_level || '')
  const [prescreenIds, setPrescreenIds] = useState(props.prescreenIds || [])
  const dispatch = useDispatch()
  const prescreenRunInputRef = useRef()
  const prescreenProjectInputRef = useRef()

  // Init
  useEffect(() => {
    updateFilter()

    dispatch(preScreenRunsFetch('', runId, props.isRunUpdate))
    dispatch(preScreenProjectsFetch())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Event handlers
  const handlePrescreenTypeChange = (e) => {
    const type = e.target.value
    setPrescreenType(type)
    let updates = {ps_type: type}

    if (type === 'custom') {
      const isDefaultFilter = Object.values(props.prescreenData).some(value => !value)

      if (isDefaultFilter) {
        const [mode, status, comparator, level] = ['include', 'started', 'any', (props.runsCount > 0 ? 'project' : 'user')]
        setPrescreenMode(mode)
        setPrescreenStatus(status)
        setPrescreenComparator(comparator)
        setPrescreenLevel(level)

        updates = {
          ...updates,
          ps_mode: mode,
          ps_status: status,
          ps_comparator: comparator,
          ps_level: level,
        }
      }
    } else {
      updates = {
        ...updates,
        ps_mode: '',
        ps_status: '',
        ps_comparator: '',
        ps_level: ''
      }
    }

    updateFilter(updates)
  }

  const handlePrescreenModeChange = (e) => {
    const mode = e.target.value
    setPrescreenMode(mode)
    updateFilter({ps_mode: mode})
  }

  const handlePrescreenStatusComparatorChange = (e) => {
    const [status, comparator] = e.target.value.split('_')
    setPrescreenStatus(status)
    setPrescreenComparator(comparator)
    updateFilter({ps_status: status, ps_comparator: comparator})
  }

  const handlePrescreenLevelChange = (e) => {
    const level = e.target.value
    setPrescreenLevel(level)
    updateFilter({ps_level: level})

    setPrescreenIds([])
    props.resetPrescreenIds()

    if (level === 'runs') {
      dispatch(preScreenRunsFetch('', runId, props.isRunUpdate))
    } else if (level === 'projects') {
      dispatch(preScreenProjectsFetch())
    }
  }

  const handlePrescreeIdsChange = (options) => {
    setPrescreenIds(options.map((option) => option.value))
  }

  const handleKeyUp = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40)
      return

    if (prescreenLevel === 'runs') {
      setPrescreenIds([])
      dispatch(preScreenRunsFetch(prescreenRunInputRef.current.state.inputValue, runId, props.isRunUpdate))
    } else if (prescreenLevel === 'projects') {
      setPrescreenIds([])
      dispatch(preScreenProjectsFetch(prescreenProjectInputRef.current.state.inputValue))
    }
  }

  const transformOptions = (options) => {
    if (!Array.isArray(options)) {
      return []
    }

    return options.map((option) => {
      return {value: option, label: option}
    })
  }


  // Private functions
  const updateFilter = (updates) => {
    const updatedFilter = {
      ...props.prescreenData,
      ps_type: prescreenType,
      ps_mode: prescreenMode,
      ps_status: prescreenStatus,
      ps_comparator: prescreenComparator,
      ps_level: prescreenLevel,
      ...updates
    }
    const isValid = validateFilter(updatedFilter)
    props.updatePrescreenData({...updatedFilter, is_filter_valid: isValid}, prescreenIds || [])
  }

  const validateFilter = (filter) => {
    if (['does_not_matter', 'project_include', 'project_exclude'].includes(filter.type))
      return true

    if (!filter.ps_type)
      return false

    if (
      filter.ps_type === 'custom' &&
      (!filter.ps_mode || !filter.ps_status || !filter.ps_comparator || !filter.ps_level)
    ) {
      return false
    }

    return true
  }

  // Side Effects
  useEffect(() => {
    if (prescreenIds) updateFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prescreenIds])

  // UI template
  return (
    <>
      {((props.isMturk && props.userRunsCount > 0) || props.runsCount > 0) &&
        <div className="pb-3">
          <div className={`box age ${props.className}`}>
            <h2 className={`big ${props.runsCount > 0 && 'text-required'}`}>
              Previous participation
            </h2>
            <p className={`${(prescreenType === undefined || prescreenType === '') && 'po-text-danger'} po-text my-3`}>
              How would you like to handle previous participants?
            </p>

            <div className="my-2 wrap_radio">
              <label className="pb-2">
                <input
                  name="prescreen_type"
                  tabIndex="5"
                  checked={prescreenType === 'does_not_matter'}
                  onChange={handlePrescreenTypeChange}
                  type="radio"
                  value="does_not_matter"
                />
                <span className="po-text">
                  No restrictions
                </span>
              </label>
              {props.runsCount > 0 && (
                <>
                  <label className="pb-2">
                    <input
                      name="prescreen_type"
                      tabIndex="5"
                      checked={prescreenType === 'project_include'}
                      onChange={handlePrescreenTypeChange}
                      type="radio"
                      value="project_include"
                    />
                    <span className="po-text">
                      Only allow participants who have completed other runs in this project
                    </span>
                  </label>

                  <label className="pb-2">
                    <input
                      name="prescreen_type"
                      tabIndex="5"
                      checked={prescreenType === 'project_exclude'}
                      onChange={handlePrescreenTypeChange}
                      type="radio"
                      value="project_exclude"
                    />
                    <span className="po-text">
                      Exclude all participants who have started other runs in this project
                    </span>
                  </label>
                </>
              )}
              {props.isMturk && (
                <label>
                  <input
                      name="prescreen_type"
                      tabIndex="5"
                      checked={prescreenType === 'custom'}
                      onChange={handlePrescreenTypeChange}
                      type="radio"
                      value="custom"
                  />
                  <span className="po-text">
                    Custom
                  </span>
                </label>
              )}
            </div>

            {props.isMturk && prescreenType === 'custom' && (
                <div className="my-2 custom-prescreen">
                  <select
                      className="prescreen-select-box"
                      name="mode"
                      value={prescreenMode}
                      onChange={handlePrescreenModeChange}
                      tabIndex="4"
                  >
                    <option value="include">Only allow</option>
                  <option value="exclude">Exclude</option>
                </select>

                <span className="po-text mr-15">
                  participants who
                </span>

                <select
                  className="prescreen-select-box"
                  name="previousParticipation.custom.status_level"
                  value={`${prescreenStatus}_${prescreenComparator}`}
                  onChange={handlePrescreenStatusComparatorChange}
                  tabIndex="5"
                >
                  <option value="started_any">started any of</option>
                  {prescreenLevel !== 'user' && (
                    <option value="started_all">started all of </option>
                  )}
                  <option value="completed_any">completed any of</option>
                  {prescreenLevel !== 'user' && (
                    <option value="completed_all">completed all of</option>
                  )}
                </select>

                <select
                  className="my-2 prescreen-select-box"
                  name="previousParticipation.custom.ps_level"
                  value={prescreenLevel}
                  onChange={handlePrescreenLevelChange}
                  tabIndex="6">
                  {props.runsCount > 0 && (
                    <option value="project">the other runs in this project</option>
                  )}
                  <option value="runs">these specific runs</option>
                  <option value="projects">the runs in these projects</option>
                  {(
                    `${prescreenStatus}_${prescreenComparator}` !== 'started_all'
                    && `${prescreenStatus}_${prescreenComparator}` !== 'completed_all'
                  ) && (
                    <option value="user">the other runs in my account </option>
                  )}
                </select>

                {prescreenLevel === 'runs' && (
                  <div className="wrap_label mt-2 prescreen-input-container"
                       onKeyUp={handleKeyUp}>
                    <Select
                      ref={prescreenRunInputRef}
                      isMulti
                      options={transformOptions(props.prescreenRuns)}
                      noOptionsMessage={(value) => value.inputValue === '' ? null : 'No results found'}
                      onChange={handlePrescreeIdsChange}
                      defaultValue={transformOptions(props.prescreenIds)}
                      placeholder="Search runs by name or ID"
                      styles={{
                        container: styles => ({
                          ...styles,
                          width: '100%',
                          height: 'auto',
                          borderRadius: 4,
                          backgroundColor: '#fff',
                          color: 'var(--input-text-color)',
                          fontSize: 14,
                          fontFamily: 'Muli-SemiBold',
                        }),
                        valueContainer: (styles) => ({
                          ...styles,
                          padding: '5px',
                          cursor: 'text',
                        }),
                        multiValue: (styles) => ({
                          ...styles,
                          fontSize: 16
                        }),
                        indicatorsContainer: () => ({
                          display: 'none'
                        }),
                        control: (styles) => ({
                          ...styles,
                          border: '1px solid #e2e9ef',
                          height: 'auto'
                        }),
                        input: (styles) => ({...styles, paddingTop: '0px', margin: '0px'}),
                        option: (styles) => ({...styles, paddingLeft: 10}),
                        placeholder: (styles) => ({...styles, color: 'var(--placeholder-color)',}),
                      }}
                    />
                  </div>
                )}

                {prescreenLevel === 'projects' && (
                  <div className="wrap_label mt-2 prescreen-input-container"
                       onKeyUp={handleKeyUp}>
                    <Select
                      ref={prescreenProjectInputRef}
                      isMulti
                      options={transformOptions(props.prescreenProjects)}
                      noOptionsMessage={(value) => value.inputValue === '' ? null : 'No results found'}
                      onChange={handlePrescreeIdsChange}
                      defaultValue={transformOptions(props.prescreenIds)}
                      placeholder="Search projects by name or ID"
                      styles={{
                        container: styles => ({
                          ...styles,
                          width: '100%',
                          height: 'auto',
                          borderRadius: 4,
                          backgroundColor: '#fff',
                          color: 'var(--input-text-color)',
                          fontSize: 14,
                          fontFamily: 'Muli-SemiBold',
                        }),
                        valueContainer: (styles) => ({
                          ...styles,
                          padding: '5px',
                          cursor: 'text',
                        }),
                        multiValue: (styles) => ({
                          ...styles,
                          fontSize: 16
                        }),
                        indicatorsContainer: () => ({
                          display: 'none'
                        }),
                        control: (styles) => ({
                          ...styles,
                          border: '1px solid #e2e9ef',
                          height: 'auto'
                        }),
                        input: (styles) => ({...styles, paddingTop: '0px', margin: '0px'}),
                        option: (styles) => ({...styles, paddingLeft: 10}),
                        placeholder: (styles) => ({...styles, color: 'var(--placeholder-color)',}),
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      }
    </>
  )
}

const mapStateToProps = (state) => {
  if (!state.runForm)
    return {prescreenRuns: [], prescreenProjects: []}

  return {
    prescreenRuns: state.runForm.prescreenRuns?.values || [],
    prescreenProjects: state.runForm.prescreenProjects?.values || []
  }
}

export default connect(mapStateToProps)(PreviousParticipationFilter)
