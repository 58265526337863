import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import qs from 'qs'
import ReCAPTCHA from 'react-google-recaptcha'
import Cookies from 'universal-cookie'

import PreviousAnswers from './PreviousAnswers'
import ImageCheck from './ImageCheck'
import OpenEndedAttentionCheckQuestion from './pre-survey/OpenEndedAttentionCheckQuestion'

import {
  isLocalStorageSupported,
  isCookieSupported,
  clearLinkVerificationData,
  removeStorageItem
} from './common/Helpers'
import {isCintPlatform, isMturkPlatform} from '../utils/constants'

import {
  preSurveyQuestionsFetch,
  preSurveyQuestionsInit,
  markPreSurveyStartedInit,
  markPreSurveyStartedSubmit,
  createAnswersSubmit,
  validateAnswerFetch
} from '../redux/actions'
import {isRealEnglish} from '../utils/helper'
import Typo from 'typo-js'
import TextAttentionCheckQuestion from './pre-survey/TextAttentionCheckQuestion'

const PreSurvey = (props) => {
  const eligibleStatusesToGetQuestions = [
    'accepted_hit',
    'pre_survey_started',
    'quality_pre_survey_started',
    'demographic_pre_survey_started',
    'issue_reported_by_participant'
  ]
  const eligibleStatuesForTaskRedirect = [
    'pre_survey_started',
    'quality_pre_survey_started',
    'screening_survey_started',
    'demographic_pre_survey_started',
    'pre_survey_completed',
    'participant_blocked_for_low_quality',
    'main_activity_started',
    'issue_reported_by_participant'
  ]
  const eligibleStatusesForRecaptcha = [
    'pre_survey_started',
    'quality_pre_survey_started',
    'issue_reported_by_participant'
  ]
  const BONUS_ACTIVITY_STATUSES = [
    'demographic_pre_survey_started',
    'main_activity_started'
  ]
  const queryParams = qs.parse(props.location.search, {ignoreQueryPrefix: true})
  const {
    worker_id: workerId,
    parent_run_id: runId,
    assignment_id: assignmentId,
    gender,
    year_of_birth: yearOfBirth
  } = queryParams

  const cookies = new Cookies()

  const storeAssignmentData = () => {
    if (isLocalStorageSupported) {
      localStorage.setItem('assignment_id', assignmentId)
      localStorage.setItem('worker_id', workerId)
      localStorage.setItem('run_id', runId)
    }

    if (isCookieSupported) {
      cookies.set('assignment_id', assignmentId)
      cookies.set('worker_id', workerId)
      cookies.set('run_id', runId)
    }
  }
  const [abortController, setAbortController] = useState(null)
  const [hiddenSpammerQuestion, setHiddenSpammerQuestion] = useState(null)
  const assignment = useSelector(state => state.preSurvey?.assignment?.data)
  const preSurveyDetails = useSelector(state => state.preSurvey?.questions?.data)
  const previousAnswers = useSelector(state => state.preSurvey?.questions?.data?.previous_answers)
  const createAnswersStatus = useSelector(state => state.preSurvey?.createAnswers?.status)
  const validateAnswerDetails = useSelector(state => state.validateAnswerForPreSurvey)
  const error = useSelector(state => (
    state.preSurvey?.assignment?.errors ||
    state.preSurvey?.questions?.errors ||
    state.preSurvey?.createAnswers?.errors
  ))
  const isLoading = useSelector(state => (
    state.preSurvey?.assignment?.isLoading ||
    state.preSurvey?.questions?.isLoading ||
    state.preSurvey?.createAnswers?.isLoading
  ))
  const isValidatingAnswer = useSelector(state => state.validateAnswerForPreSurvey?.isLoading)

  const dispatch = useDispatch()
  const dictionaryRef = useRef(null)

  const [answers, setAnswers] = useState([])
  const [updatedAnswers, setUpdatedAnswers] = useState([])
  const [cintAnswers, setCintAnswers] = useState([])
  const [imageCheckVerified, setImageCheckVerified] = useState(true)
  const [captchaVerified, setCaptchaVerified] = useState(false)
  const [showCaptcha, setShowCaptcha] = useState(false)
  const [captchaKey, setCaptchaKey] = useState('')
  const [showConsent, setShowConsent] = useState(false)
  const [isRedirect, setIsRedirect] = useState(false)
  const [keyStrokeTimings, setKeyStrokeTimings] = useState([])
  const [keyCount, setKeyCount] = useState({
    ArrowUp: 0,
    ArrowDown: 0,
    ArrowLeft: 0,
    ArrowRight: 0,
    Backspace: 0,
    Delete: 0,
    Meta: 0,
    Control: 0,
    Alt: 0,
    Shift: 0,
    Enter: 0,
    CapsLock: 0,
  })
  const [keysTyped, setKeysTyped] = useState([])
  const [inputEvents, setInputEvents] = useState({
    focus: 0,
    blur: 0,
    paste: 0,
    mouse_paste: 0
  })
  const [textAttentionCheckQuestion, setTextAttentionCheckQuestion] = useState(null)

  const isCint = assignment && isCintPlatform(assignment.platform_type)
  const isMturk = assignment && isMturkPlatform(assignment.platform_type)
  const isBonusActivity = isMturk && assignment?.agree_screening_guidelines && BONUS_ACTIVITY_STATUSES.includes(assignment.status)
  const isPostSurveyApplicable = isCint || isBonusActivity || assignment?.agree_screening_guidelines === false

  const getContinueButtonLabel = () => {
    if (isLoading)
      return 'Please wait ...'

    if (isBonusActivity)
      return 'Continue To Bonus Activity'

    return 'Continue To Main Activity'
  }

  const markPreSurveyStarted = () => {
    if (!assignmentId || !runId) return

    let params = {
      assignment_id: assignmentId,
      run_id: runId,
      worker_id: workerId
    }
    dispatch(markPreSurveyStartedInit())
    dispatch(markPreSurveyStartedSubmit(params))
  }

  // Get formatted task url for redirection
  const getUpdatedTaskUrl = () => {
    let queryString, taskUrl, updatedTaskUrl
    if (assignment) {
      delete assignment.worker_details['notify']
      delete assignment.worker_details['is_demograhic_already_answered']

      queryString = qs.stringify(assignment.worker_details)
      taskUrl = assignment.task_url

      if (!/^https?:\/\//.test(taskUrl))
        taskUrl = 'http://' + taskUrl

      if (/^https?:\/\/(.*)\?(.*)/.test(taskUrl))
        updatedTaskUrl = taskUrl + '&' + queryString
      else
        updatedTaskUrl = taskUrl + '?' + queryString
    }

    return updatedTaskUrl
  }

  const getRedirectionMessage = () => {
    if (eligibleStatuesForTaskRedirect.includes(assignment?.status))
      return 'Your activity will begin momentarily...'

    if (assignment?.status === 'feedback_started')
      return 'Redirecting to the feedback page...'

    return 'Please wait...'
  }

  // Redirect to task url
  const redirectToTaskWebsite = () => {
    window.location.href = getUpdatedTaskUrl()
  }

  const redirectToFeedbackPage = () => {
    props.history.push({pathname: '/f', search: `?task_id=${assignment.task_uid}`})
  }

  const redirectToCintReturnUrl = () => {
    if (assignment.cint_return_url) window.location.href = assignment.cint_return_url
  }

  const getQuestions = () => {
    let params = {
      worker_id: workerId,
      run_id: runId
    }

    let imageCheckQuestionId
    if (isLocalStorageSupported)
      imageCheckQuestionId = localStorage.getItem('image_check_question_id')
    else if (isCookieSupported)
      imageCheckQuestionId = cookies.get('image_check_question_id')

    if (imageCheckQuestionId)
      params.image_check_question_id = imageCheckQuestionId

    dispatch(preSurveyQuestionsInit())
    dispatch(preSurveyQuestionsFetch(params))
  }

  const defaultCintQuestions = {
    age: 'What year were you born?',
    gender: 'What is your gender?'
  }

  const getAnswerOptionId = (questionText, answerText) => {
    const selectedAnswer = cintAnswers.find(answer => answer.question_text === questionText)

    if (selectedAnswer) {
      const selectedAnswerOption = selectedAnswer.answer_options.find(answerOption => answerOption.answer_text === answerText)

      if (selectedAnswerOption)
        return selectedAnswerOption.id
    }
  }

  const mapCintGenderToPositly = (cintGender) => {
    if (gender === '1')
      return 'Male'
    else if (gender === '2')
      return 'Female'
    else
      return 'Other'
  }

  const setupAnswersForCintWorker = () => {
    const genderAnswerText = mapCintGenderToPositly(gender)
    const answersFromCintQueryParams = [
      {
        question_text: defaultCintQuestions.age,
        answer_text: yearOfBirth
      }, {
        question_text: defaultCintQuestions.gender,
        answer_option_id: getAnswerOptionId(defaultCintQuestions.gender, genderAnswerText)
      }
    ]

    const updatedCintAnswer = cintAnswers.map(answer => {
      const selectedAnswer = answersFromCintQueryParams.find(cintAnswer => cintAnswer.question_text === answer.question_text)

      if (selectedAnswer) return {...answer, ...selectedAnswer}

      return answer
    })

    setCintAnswers(updatedCintAnswer)
  }

  const formatQuestions = () => {
    let formattedAnswers = []
    let formattedCintAnswers = []

    preSurveyDetails.questions.forEach((question) => {
      if (question.question_type === 'image_check_question') {
        if (isLocalStorageSupported && !localStorage.getItem('image_check_question_id'))
          localStorage.setItem('image_check_question_id', question.id)

        if (isCookieSupported && !cookies.get('image_check_question_id'))
          cookies.set('image_check_question_id', question.id)
      }

      let formattedQuestion = {}
      formattedQuestion.question_id = question.id
      formattedQuestion.question_type = question.question_type
      formattedQuestion.question_text = question.question_text
      formattedQuestion.answer_type = question.answer_type
      formattedQuestion.image_url = question.image_url

      if (question.label)
        formattedQuestion.label = question.label.toLowerCase()

      if (formattedQuestion.answer_type === 'text') {
        formattedQuestion.answer_text = ''
        formattedQuestion.is_invalid = false
      } else {
        formattedQuestion.answer_options = question.answer_options
        formattedQuestion.answer_option_id = ''
        formattedQuestion.is_invalid = false
      }

      const isCintDefault = Object
        .keys(defaultCintQuestions)
        .map(key => defaultCintQuestions[key])
        .includes(formattedQuestion.question_text)

      if (isCint && isCintDefault)
        formattedCintAnswers.push(formattedQuestion)
      else {
        formattedAnswers.push(formattedQuestion)
      }
    })

    // HSD
    const testQuestions = formattedAnswers.filter(question => question.question_type === 'test_question')
    // React batches state changes together and tries to minimize renders, because of this behaviour state changes
    // may not be available immediately in the same context.
    // We can use setTimeout (microtaskers queue) to access the state changes.
    setTimeout(() => {
      setTextAttentionCheckQuestion(() => testQuestions[0])
    }, 0)

    const hiddenQuestion = testQuestions[1]
    if (hiddenQuestion) {
      const answerForHiddenQuestion = {
        question_id: hiddenQuestion.question_id,
        is_invalid: false
      }
      setHiddenSpammerQuestion(hiddenQuestion)
      updateAnswer(answerForHiddenQuestion)
    }

    setAnswers(formattedAnswers)
    setCintAnswers(formattedCintAnswers)
  }

  const setupCaptcha = () => {
    if (eligibleStatusesForRecaptcha.includes(assignment?.status) && (preSurveyDetails.survey_count === 0 ||
      ((preSurveyDetails.survey_count + 1) % 10) === 0 || assignment?.ask_all_attention_checks)) {
      setCaptchaKey(preSurveyDetails.recaptcha_key)
      setShowCaptcha(true)
    } else {
      setCaptchaVerified(true)
    }
  }

  const setupImageCheck = () => {
    const imageCheckQuestion = preSurveyDetails.questions.filter(question => question.question_type === 'image_check_question')

    if (imageCheckQuestion?.length > 0)
      setImageCheckVerified(false)
    else
      setImageCheckVerified(true)
  }

  const isInvalidAnswer = (questionId) => {
    const answer = updatedAnswers.find(answer => answer.question_id === questionId)
    return answer && answer.is_invalid
  }

  const updateAnswer = (answer) => {
    if(answer.is_image_check_answer){
      document.getElementById('pre-survey-top').scrollIntoView()
      delete answer.is_image_check_answer
    }
    const unchangedAnswers = updatedAnswers.filter(updatedAnswer => updatedAnswer.question_id !== answer.question_id)
    setUpdatedAnswers([...unchangedAnswers, answer])
  }

  // Validate answer
  const validateAnswer = (e, skipUpdateAnswer = false, ui = 'PreSurvey') => {
    const questionId = e.target.name, answerText = e.target.value, answerType = e.target.type

    const isInvalid = hiddenSpammerQuestion?.question_id === questionId ? false : !answerText

    let answer = {
      question_id: questionId,
      is_invalid: isInvalid
    }
    answerType === 'select-one' ? answer.answer_option_id = answerText : answer.answer_text = answerText

    if (!skipUpdateAnswer)
      updateAnswer(answer)

    if (answerText === '')
      return

    const controller = new AbortController()
    const {signal} = controller

    if (abortController !== null) abortController.abort()
    setAbortController(controller)

    const params = {
      question_id: questionId,
      answer: answerText
    }
    if (answerType === 'number') {
      dispatch(validateAnswerFetch(params, signal, ui))
    }
  }

  // Create answer
  const createAnswers = (e) => {
    if (e) e.preventDefault()

    const mergedAnswers = answers.map(answer => {
      const answerToMerge = updatedAnswers.find(updatedAnswer => updatedAnswer.question_id === answer.question_id)
      return {...answer, ...answerToMerge}
    })

    let params = {
      answers: [...mergedAnswers, ...cintAnswers],
      assignment_id: assignmentId,
      worker_id: workerId,
      run_id: runId,
      char_typing_time_in_ms: keyStrokeTimings,
      keys_typed: JSON.stringify(keysTyped),
      special_keys_typed: keyCount,
      input_events: inputEvents
    }

    const open_ended_question = mergedAnswers.find(answer => answer.question_type === 'open_ended_attention_check_question')

    if (open_ended_question) {
      const browserData = {
        b_screen_resolution_width: window.screen.width,
        b_screen_resolution_height: window.screen.height,
        b_viewport_width: window.innerWidth,
        b_viewport_height: window.innerHeight,
        b_window_width: window.outerWidth,
        b_window_height: window.outerHeight,
        b_platform: navigator.userAgentData?.platform,
        b_cookie_enabled: navigator.cookieEnabled,
        b_time_zone: Intl.DateTimeFormat()?.resolvedOptions()?.timeZone,
        b_connection_type: navigator.connection?.effectiveType,
        b_language: navigator.language,
      }

      const oeac_status = isRealEnglish(dictionaryRef.current, open_ended_question.answer_text)
      params = {...params, oeac_status, ...browserData}
    }

    dispatch(createAnswersSubmit(params))
  }

  // Check if form is valid
  const isFormValid = () => {
    const isAnswersValid = !updatedAnswers.some(answer => answer.is_invalid)
    return captchaVerified && imageCheckVerified && updatedAnswers.length === answers.length && isAnswersValid
  }

  // Will be called when recaptcha expired or successfully verified
  const onRecaptchaChange = (value) => {
    setCaptchaVerified(!!value)
  }

  // Initialize Pre Survey
  useEffect(() => {
    if (!isLocalStorageSupported && !isCookieSupported) return

    removeStorageItem('is_recruiter')
    clearLinkVerificationData()
    markPreSurveyStarted()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Side effects for assignment
  useEffect(() => {
    if (!assignment) return

    storeAssignmentData()

    if (eligibleStatusesToGetQuestions.includes(assignment.status))
      getQuestions()
    else if (eligibleStatuesForTaskRedirect.includes(assignment.status)) {
      setIsRedirect(true)
      setTimeout(redirectToTaskWebsite, 1000)
    } else if (assignment.status === 'feedback_started') {
      setIsRedirect(true)
      setTimeout(redirectToFeedbackPage, 1000)
    } else if (['quota_full', 'ineligible'].includes(assignment.status)) {
      setIsRedirect(true)
      setTimeout(redirectToCintReturnUrl, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignment])

  // Side effects for questions
  useEffect(() => {
    const questions = preSurveyDetails?.questions
    if (questions) {
      if (questions.length === 0) {
        return createAnswers()
      }

      formatQuestions()
      setupCaptcha()
      setupImageCheck()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preSurveyDetails?.questions])

  // Setup answers based on questions
  useEffect(() => {
    if (answers?.length > 1)
      setShowConsent(true)

    if (isCint) {
      if (answers?.length === 0)
        return createAnswers()

      setupAnswersForCintWorker()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers])

  useEffect(() => {
    if (createAnswersStatus === 'success') {
      if (isLocalStorageSupported) localStorage.removeItem('image_check_question_id')
      if (isCookieSupported) cookies.remove('image_check_question_id')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAnswersStatus])

  useEffect(() => {
    if (!validateAnswerDetails) return
    let answer = updatedAnswers.find(answer => answer.question_id === validateAnswerDetails.questionId)

    if (validateAnswerDetails.status === 'error' && answer) {
      updateAnswer({...answer, is_invalid: true})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateAnswerDetails])

  useEffect(() => {
    dictionaryRef.current = new Typo('en_US', false, false, {dictionaryPath: 'dictionaries'})
  }, [])

  return (
    <>
      {!isLocalStorageSupported && !isCookieSupported && (
        <div className="row po-ns">
          <div className="col-lg-12">
            <div className="metrics clear-h3-margin box">
              <h3>
                Participation in this task requires either LocalStorage or Cookie to be enabled, but your current
                browser is blocking the use of LocalStorage and Cookie. Please adjust your browser settings,
                turn off plugins that may block localStorage and Cookie, or try a different web browser
                in order to participate.
              </h3>
              <div className="po-note mt-4">
                Note: You must refresh the window after you have enabled the LocalStorage or Cookie
                in order to continue the participation.
              </div>
            </div>
          </div>
        </div>
      )}

      {(isLocalStorageSupported || isCookieSupported) && (
        <div id="pre-survey-top" className="pre-survey-form row task-list-header align-items-center po-ns">
          <div className="col-lg-12">
            <ul className="status_bar">
              <li className="item step_1 passed">
                <div className="circle"/>
                <p>Accepted</p>
              </li>
              {assignment?.activity_language_name === 'English' && (
                <li className={`item step_2 ${assignment?.survey_started_at ? 'passed' : 'active'}`}>
                  <div className="circle"/>
                  <p>Pre-survey</p>
                </li>
              )}
              <li className={`item step_3 ${assignment?.feedback_started_at ? 'passed' : ''}`}>
                <div className="circle"/>
                <p>{isBonusActivity ? 'Bonus' : 'Main'} activity</p>
              </li>
              {isPostSurveyApplicable && (
                <li className={`item step_4 ${assignment?.feedback_completed_at ? 'passed' : ''}`}>
                  <div className="circle"/>
                  <p>Post-survey</p>
                </li>
              )}
              <li className={`item step_4 ${assignment?.mturk_submitted_at ? 'passed' : ''}`}>
                <div className="circle"/>
                <p>Submitted</p>
              </li>
            </ul>
          </div>

          {answers?.length > 0 && !isRedirect && !isLoading && (
            <div className="metrics project_detail filter participants activity col-md-12 po-ns">
              <div className="wrap_title row align-items-center">
                <h1>
                  <span>Pre-survey</span>
                </h1>
              </div>

              <form
                className="create"
                name="surveyForm"
                onSubmit={createAnswers}
              >
                <div className="row">
                  <div className="left col-md-6">
                    <div className={`pre-survey-answers ${hiddenSpammerQuestion ? 'po-box' : ''}`}>
                      {answers.map((answer, index) => {
                        if (answer.question_type === 'image_check_question' && !imageCheckVerified) {
                          return (
                            <ImageCheck
                              key={answer.question_id}
                              answer={answer}
                              setImageCheckVerified={setImageCheckVerified}
                              updateAnswer={updateAnswer}
                            />
                          )
                        } else if (imageCheckVerified && !['image_check_question', 'open_ended_attention_check_question'].includes(answer.question_type) && answer.answer_type === 'text') {
                          return (
                            <div key={answer.question_id} className="box mb-3 po-ns">
                              <div
                                className={`form-group ${isInvalidAnswer(answer.question_id) ? 'has-error' : ''}`}>
                                <label>
                                  <h2 className="text-required pre-survey-question">{answer.question_text}</h2>
                                </label>
                                <input
                                  required={true}
                                  name={answer.question_id}
                                  onChange={validateAnswer}
                                  step="1"
                                  type="number"
                                />
                                {isInvalidAnswer(answer.question_id) && (
                                  <p className="po-text-danger po-text">
                                    Invalid {answer.label}
                                  </p>
                                )}
                              </div>
                            </div>
                          )
                        } else if (imageCheckVerified && answer.question_type !== 'image_check_question' && answer.answer_type === 'select_box') {
                          if (answer.question_type === 'test_question' && answer.question_id === textAttentionCheckQuestion?.question_id) {
                            return (
                              <TextAttentionCheckQuestion
                                key={answer.question_id}
                                answer={answer}
                                isInvalidAnswer={isInvalidAnswer}
                                validateAnswer={validateAnswer}
                              />
                            )
                          } else {
                            return (
                              <div key={answer.question_id} className="box mb-3 po-ns po-ns-select">
                                <div
                                  className={`form-group ${isInvalidAnswer(answer.question_id) ? 'has-error' : ''}`}>
                                  <label>
                                    <h2 className="text-required pre-survey-question">{answer.question_text}</h2>
                                  </label>
                                  <select
                                    className="full-width"
                                    id={answer.question_id}
                                    name={answer.question_id}
                                    onChange={validateAnswer}
                                  >
                                    <option value="">Select one</option>
                                    {answer.answer_options.map((answerOption, index) => (
                                      <option key={index} value={answerOption.id}>
                                        {answerOption.answer_text}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            )
                          }
                        } else if (imageCheckVerified && answer.question_type === 'open_ended_attention_check_question') {
                          return (
                            <OpenEndedAttentionCheckQuestion
                              key={answer.question_id}
                              answer={answer}
                              isInvalidAnswer={isInvalidAnswer}
                              validateAnswer={validateAnswer}
                              setKeyStrokeTimings={setKeyStrokeTimings}
                              setKeyCount={setKeyCount}
                              setKeysTyped={setKeysTyped}
                              setInputEvents={setInputEvents}
                            />
                          )
                        } else {
                          return null
                        }
                      })}
                    </div>

                    {imageCheckVerified && (
                      <div>
                        {showCaptcha && (
                          <div className="my-2">
                            <ReCAPTCHA
                              sitekey={captchaKey}
                              onChange={onRecaptchaChange}
                            />
                          </div>
                        )}
                        {showConsent && isPostSurveyApplicable && (
                          <div className="po-text link-visited">
                            By continuing to the {isBonusActivity ? 'bonus' : 'main'} activity, you agree to the {' '}
                            <a href="https://www.positly.com/terms/" target="_blank" rel="noreferrer">
                              terms and conditions
                            </a>
                            {' '}and consent for your{' '}
                            <span className="po-link-color">
                              data {' '}
                              <div className="d-inline-block po-tooltip participants-attributes-tooltip">
                                <span className="po-tooltiptext po-tooltip-position">
                                  Year of Birth <br/>
                                  Gender <br/>
                                  Education <br/>
                                  Household Income
                                </span>
                              </div>
                            </span>
                            to be used by researchers running this study and stored by Positly.
                          </div>
                        )}
                        <div className="wrap_link my-3">
                          <button
                            className={`btn btn-primary no-border clickable ${(!isFormValid() || isLoading || isValidatingAnswer) && 'cursor-disabled btn-grey'}`}
                            data-loading-text="Please wait ..."
                            id="answers-button"
                            disabled={!isFormValid() || isLoading || isValidatingAnswer}
                            type="submit"
                          >
                            {getContinueButtonLabel()}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  {previousAnswers?.length > 0 && (
                    <PreviousAnswers
                      assignmentId={assignmentId}
                      answers={previousAnswers}
                      platform={assignment.platform_type}
                      defaultCintQuestions={defaultCintQuestions}
                      validateAnswer={validateAnswer}
                    />
                  )}
                </div>
              </form>
            </div>
          )}
        </div>
      )}

      {(isLocalStorageSupported || isCookieSupported) && (
        <div className="row po-ns">
          <div className="col-md-8 col-sm-12">
            <div className="metrics filter participants clear-h3-margin">
              {!isLoading && !error && (
                <div>
                  {isRedirect && (
                    <div className="box">
                      <h3>{getRedirectionMessage()}</h3>
                    </div>
                  )}
                  {assignment?.status === 'feedback_completed' && (
                    <div className="box">
                      <h3>You have already completed this activity. Please return to Mturk and submit the HIT</h3>
                    </div>
                  )}
                  {(assignment?.status === 'task_completed' || assignment?.status === 'approved') && (
                    <div className="box">
                      <h3>You have already completed this activity</h3>
                    </div>
                  )}
                  {assignment?.status === 'abandoned' && (
                    <div className="box">
                      <h3>
                        You exceeded the time limit for this HIT. This HIT has been marked as expired in
                        Amazon Mechanical Turk.
                      </h3>
                    </div>
                  )}
                  {assignment?.status === 'returned' && (
                    <div className="box">
                      <h3>
                        You have already returned this assignment in Amazon Mechanical Turk
                      </h3>
                    </div>
                  )}
                  {assignment?.status === 'rejected' && (
                    <div className="box">
                      <h3>
                        Your assignment was already rejected in Amazon Mechanical Turk because it was submitted
                        to Amazon Mechanical Turk before completing the activity.
                      </h3>
                    </div>
                  )}
                  {assignment?.status === 'mturk_submitted' && (
                    <div className="box">
                      <h3>
                        You have already submitted this HIT in Amazon Mechanical Turk
                      </h3>
                    </div>
                  )}
                  {assignment?.status === 'expired_during_main_activity' && (
                    <div className="box">
                      <h3>
                        Your bonus activity timed out. You are no longer qualified to continue the bonus activity.
                      </h3>
                    </div>
                  )}
                </div>
              )}
              {isLoading && (
                <div className="box">
                  <h3>Please wait...</h3>
                </div>
              )}
              {error && (
                <div className="box">
                  <h3 className="text-danger">{error}</h3>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PreSurvey
