import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import * as Yup from 'yup'
import {useFormik} from 'formik'

import {runFormOverviewUpdate} from '../../../redux/actions'
import {removeHighlight, urlRegex} from './RunUtils'
import MTurkScreeningSetupPopup from './MTurkScreeningSetupPopup'
import {GA_EVENTS, navigateToDetailsTab, sendGAEvent} from '../../../utils/helper'

const $ = window.$

const MTurkScreening = (
  {
    setScreeninRate,
    showBudgetChangedWarning,
    setShowBudgetChangedWarning,
    openScreeningSetup,
    setOpenScreeningSetup,
    setActiveTab,
    mturkScreenerFormRef
  }) => {
  const dispatch = useDispatch()
  const [isScreeningFormValid, setIsScreeningFormValid] = useState(false)
  const [isScreeningLinkSameAsActivityLink, setIsScreeningLinkSameAsActivityLink] = useState(false)

  const {
    isQuoteLoading,
    agreeScreeningGuidelines,
    estScreeningDuration,
    estScreeningDurationType,
    estIncidenceRate,
    estMaxBudget,
    screeningActivityLink,
    taskUrl,
    quote,
    isDetailsFormValid,
    positly_user_id,
    platform
  } = useSelector(state => ({
    isQuoteLoading: state.runForm.quote?.isLoading,
    agreeScreeningGuidelines: state.runForm.agree_screening_guidelines,
    estScreeningDuration: state.runForm.task_parameters.est_screening_duration,
    estScreeningDurationType: state.runForm.task_parameters.est_screening_duration_type,
    estIncidenceRate: state.runForm.task_parameters.est_incidence_rate,
    estMaxBudget: state.runForm.task_parameters.est_max_budget,
    screeningActivityLink: state.runForm.task_parameters.screening_activity_link,
    taskUrl: state.runFormOverview.task_parameters.task_url,
    quote: state.runForm.quote,
    isDetailsFormValid: state.runFormOverview.isDetailsFormValid,
    positly_user_id: state.currentUser?.uid,
    platform: state.runFormOverview?.platform?.full_name
  }))

  const initialValues = {
    agree_screening_guidelines: agreeScreeningGuidelines || false,
    task_parameters: {
      screening_activity_link: screeningActivityLink || '',
      est_screening_duration: estScreeningDuration || 0,
      est_screening_duration_type: estScreeningDurationType || 'minutes',
      est_incidence_rate: estIncidenceRate || 0,
      est_max_budget: estMaxBudget || 0,
    }
  }

  const validations = {
    task_parameters: Yup.object().shape({
      screening_activity_link: Yup.string()
        .required('Screening activity link is required')
        .matches(urlRegex, 'Screening activity link is invalid'),
      est_screening_duration: Yup.number()
        .required('Estimated screening duration is required')
        .when('est_screening_duration_type', {
          is: (value) => value === 'seconds',
          then: (schema) => schema
            .min(30, 'Minimum screening duration is 30 seconds')
            .max(180, 'Your screening questions can’t take longer than 3 minutes'),
        })
        .when('est_screening_duration_type', {
          is: (value) => value === 'minutes',
          then: (schema) => schema
            .min(0.5, 'Minimum screening duration is 30 seconds')
            .max(3, 'Your screening questions can’t take longer than 3 minutes'),
        }),
      est_screening_duration_type: Yup.string()
        .required('Estimated screening duration type is required'),
      est_incidence_rate: Yup.number()
        .required('Estimated eligibility rate is required')
        .min(3, `Estimated eligibility rate cannot be less than 3%`)
        .max(99, `Estimated eligibility rate cannot exceed 99%`),
      est_max_budget: Yup.number()
        .required('Max budget is required')
        .test({
          name: 'testMaxBudget',
          message: `Max Budget cannot be less than $${quote?.totalRunCost}`,
          test: (value) => (quote?.totalRunCost ? value >= quote.totalRunCost : true),
        }),
    }),
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object(validations),
  })

  const openScreeningSetupPopUp = (e) => {
    if (e) {
      e.preventDefault()
    }

    if (!isDetailsFormValid) {
      return navigateToDetailsTab(setActiveTab)
    }

    sendGAEvent(GA_EVENTS.CLICK_SETUP_SCREENING_ACTIVITY, {positly_user_id, platform})
    $.magnificPopup.open({
      items: {src: `#mturk-screening`},
      type: 'inline',
      modal: true,
      closeOnBgClick: false
    })
  }

  const validateAndDispatchForm = (formValues) => {
    if (!formik.values.agree_screening_guidelines) {
      const values = {
        agree_screening_guidelines: false,
        task_parameters: {
          screening_activity_link: undefined,
          est_screening_duration: undefined,
          est_screening_duration_type: undefined,
          est_incidence_rate: undefined,
          est_max_budget: undefined,
        }
      }

      setIsScreeningFormValid(true)
      return dispatch(runFormOverviewUpdate({...values, isScreeningFormValid: true}))
    }

    formik.validateForm().then((data) => {
      const isScreeningFormValid = data && !Object.keys(data).length && !isScreeningLinkSameAsActivityLink
      const values = formValues ? formValues : formik.values

      setIsScreeningFormValid(isScreeningFormValid)
      dispatch(runFormOverviewUpdate({...values, isScreeningFormValid}))
    })
  }

  useEffect(() => {
    if (!formik.values.agree_screening_guidelines)
      setShowBudgetChangedWarning(false)

    validateAndDispatchForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.agree_screening_guidelines])


  useEffect(() => {
    if (estIncidenceRate) setScreeninRate(estIncidenceRate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estIncidenceRate])

  useEffect(() => {
    if (!quote?.totalRunCost) return

    formik.setFieldValue('task_parameters.est_max_budget', quote.defaultMaxBudget)
    dispatch(runFormOverviewUpdate({costEstimate: quote.totalRunCost}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote?.totalRunCost])

  useEffect(() => {
    const screeningUrl = formik.values.task_parameters?.screening_activity_link
    if (!screeningUrl || !taskUrl)
      return setIsScreeningLinkSameAsActivityLink(false)

    setIsScreeningLinkSameAsActivityLink(screeningUrl === taskUrl)
  }, [formik.values.task_parameters?.screening_activity_link, taskUrl])

  useEffect(() => {
    if (!openScreeningSetup)
      return

    openScreeningSetupPopUp()
    setOpenScreeningSetup(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openScreeningSetup])

  if (formik.values.agree_screening_guidelines && isScreeningFormValid && !showBudgetChangedWarning)
    removeHighlight('screening-setup')

  return (
    <>
      <div className="box" ref={mturkScreenerFormRef}>
        <h2 className="font-size-15 mb-2">
          If you don't find the filters you need, add a screening activity to limit who participates (optional)
        </h2>

        <div className="wrap_info_form help-text">
          <span>
            While filters are the simplest way to limit who participates (see the filter section above), you can also
            design your own screening activity with your own custom questions to determine participant eligibility.
          </span>
        </div>

        <div className="d-flex justify-content-center p-2 mt-3" id="screening-setup">
          <Link
            className="link"
            to=""
            tabIndex="4"
            onClick={openScreeningSetupPopUp}
          >
            Set up Screening Activity
          </Link>
          {formik.values.agree_screening_guidelines && isScreeningFormValid && !showBudgetChangedWarning
            ? <label className="screening-setup success-tick-filled">&nbsp;</label>
            : null
          }
        </div>

        <div>
          {(formik.values.agree_screening_guidelines && showBudgetChangedWarning && quote.totalRunCost) ? (
            <p className="po-text text-red-soft text-center mb-2">
              Your cost estimate has changed. Please update your screening setup.
            </p>
          ) : null}
        </div>

        <MTurkScreeningSetupPopup
          formik={formik}
          costEstimate={quote?.totalRunCost}
          defaultMaxBudget={quote?.defaultMaxBudget}
          setScreeninRate={setScreeninRate}
          validateAndDispatchForm={validateAndDispatchForm}
          isQuoteLoading={isQuoteLoading}
          setShowBudgetChangedWarning={setShowBudgetChangedWarning}
          isScreeningLinkSameAsActivityLink={isScreeningLinkSameAsActivityLink}
        />
      </div>
    </>
  )
}

export default MTurkScreening
