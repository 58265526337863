import React, {useEffect, useRef, useState} from 'react'
import {connect, useDispatch} from 'react-redux'

// 3rd party components
import {useFormik} from 'formik'
import * as Yup from 'yup'
import swal from 'sweetalert'

// Custom components & helpers
import Input from '../../common/Input'
import SaveAsDraftButton from '../SaveAsDraftButton'
import Spinner from '../../common/Spinner'
import CancelButton from './CancelButton'
import CountryPlatformSelect from '../../common/CountryPlatformSelect'
import ActivityLanguageSelect from '../../common/ActivityLanguageSelect'
import {
  generateQuotaGroups,
  getActivityLanguageById,
  getActivityLanguageByName,
  getFormattedCintAttributes, getFormattedRegionIds,
  getFormattedVariableIds,
} from './RunUtils'
import LowEligibleParticipantsWarning from '../../common/LowEligibleParticipantsWarning'
import Currency from '../../common/Currency'
import {TABS} from './EnhancedRunForm'
import GuidedTrackPromo from '../../GuidedTrackPromo'
import {isCintPlatform} from '../../../utils/constants'

// Redux actions
import {
  quoteForRunFetch,
  quoteForRunReset,
  runFormOverviewUpdate,
  runFormProfilingVariablesFetch,
  runFormDisablePilotWarning,
  runFormQualifiedWorkersCountForCintFetch,
  runFormQualifiedWorkersCountForCintReset,
  runFormResetFiltersAndQueryStrings,
  runFormLoadDefaultQueryStrings, runFormOverviewAddOrUpdatePrescreenData,
} from '../../../redux/actions'
import PlatformSelect from '../../common/PlatformSelect'
import QualityFilter from './QualityFilter'
import {GA_EVENTS, sendGAEvent} from '../../../utils/helper'
import ReactTooltip from "react-tooltip";

const RunFormDetailsTab = (props) => {
  // Variables
  const titleLimit = 110
  const mturkMaxExpiryValues = {minutes: 525600, hours: 8760, weeks: 52, days: 365}
  const timeMultiplyValues = {minutes: 1, hours: 60, days: 1440, weeks: 10080}
  const [formattedTotalRunCost, setFormattedTotalRunCost] = useState()
  const [hidePilotWarning, setHidePilotWarning] = useState(false)
  const [isCountryChanged, setIsCountryChanged] = useState(false)
  const [isPlatformChanged, setIsPlatformChanged] = useState(false)
  const [isPayPerSubmissionChanged, setIsPayPerSubmissionChanged] = useState(false)
  const [cintEligibleAbortController, setCintEligibleAbortController] = useState(null)
  const [quoteAbortController, setQuoteAbortController] = useState(null)
  const [validateOnce, setValidateOnce] = useState(true)
  const expiresAfterRef = useRef()
  const numberOfSubmissionsRef = useRef()
  const maxTimePerSubmissionRef = useRef()
  const previousTotalRunCostRef = useRef()
  const [availablePlatforms, setAvailablePlatforms] = useState([])

  const dispatch = useDispatch()

  const isIncidenceRateValid = props.isCint
    ? props.screeninRate >= props.minIncidenceRate && props.screeninRate <= props.maxIncidenceRate
    : true

  const getInitialActivityLanguageId = () => (
    props.activity_language_id
    || props.currentUser?.default_activity_language_id
    || getActivityLanguageByName(props.languages, 'English').id
  )

  let maxTimePerSubmission = props.task_parameters.max_time_per_submission === ''
    ? ''
    : parseFloat(props.task_parameters.max_time_per_submission)

  /* Input validations */
  const additionalValidationsForMturk = props.isMturk
    ? {
      max_submissions_per_person: Yup.number()
        .required('Required')
        .min(1, 'Minimum is 1')
        .test({
          name: 'ifMTurkMPS',
          test: (value) => props.agree_screening_guidelines ? value === 1 : true,
          message: `Maximum is 1 when the screening is enabled`
        })
    }
    : {}

  const validations = {
    name: Yup.string()
      .required('Run name is required')
      .max(titleLimit, `Run name is limited to ${titleLimit} characters`),
    task_parameters: Yup.object().shape({
      ...additionalValidationsForMturk,
      expires_after: Yup.number()
        .required('Take down run after is required')
        .test({
          name: 'ifCint',
          test: (value) => (props.isCint ? value < 32 : true),
        }),
      expires_after_time_type: Yup.string()
        .required('Take down run after is required')
        .test({
          name: 'ifCint',
          test: (value) => (props.isCint ? value === 'days' : true),
        }),
      number_of_submissions: Yup.number()
        .required('Required')
        .min(1, 'Minimum is 1'),
      max_time_per_submission: Yup.number()
        .required('Estimated time to complete activity is required')
        .min(1)
        .test({
          name: 'ifCint',
          test: value => props.isCint ? value <= props.maxInterviewLength : true,
          message: `Your time estimate cannot exceed ${props.maxInterviewLength} minutes.`
        }),
      max_time_type: Yup.string()
        .required('Estimated time type to complete activity is required')
        .test({
          name: 'ifCint',
          test: value => props.isCint ? value === 'minutes' : true,
        }),
      payment_per_submission: Yup.number()
        .required('Payment per activity completion is required')
        .min(0.01),
    }),
    agree_pii: Yup.bool()
      .when('collects_pii', {
        is: true,
        then: Yup.bool()
          .oneOf([true], 'Please agree to the PII guidelines'),
      }),
    activity_language_id: Yup.string()
      .required('Activity language is required'),
  }

  const formik = useFormik({
    initialValues: {
      task_id: props.task_id || '',
      name: props.name || '',
      platform_id: props.platform_id || '',
      collects_pii: props.collects_pii || false,
      agree_pii: props.collects_pii || false,
      task_parameters: {
        country_name: props.task_parameters.country_name || '',
        expires_after: props.task_parameters.expires_after || '',
        expires_after_time_type: props.task_parameters.expires_after_time_type || '',
        number_of_submissions: props.task_parameters.number_of_submissions || '',
        max_submissions_per_person: props.task_parameters.max_submissions_per_person || 1,
        max_time_per_submission: maxTimePerSubmission || '',
        max_time_type: props.task_parameters.max_time_type || 'minutes',
        payment_currency: '$',
        payment_per_submission: props.task_parameters.payment_per_submission || '',
      },
      activity_language_id: getInitialActivityLanguageId()
    },
    validationSchema: Yup.object(validations),
    validateOnMount: true,
  })

  const validateFormAndDispatchValues = (updateField) => {
    formik.validateForm().then((data) => {
      const isDetailsFormValid = data && !Object.keys(data).length && !expiryTimeError() && !estAssignmentDurationError()
      const values = updateField ? {...formik.values, ...updateField} : formik.values

      dispatch(runFormOverviewUpdate({...values, isDetailsFormValid}))
    })
  }

  // Event handlers
  const handleBlur = () => {
    validateFormAndDispatchValues()
  }

  const disablePilotWarning = (e) => {
    e.preventDefault()
    setHidePilotWarning(true)

    swal({
      title: 'Are you sure?',
      text: 'Would you like to disable this warning message permanently?',
      icon: 'warning',
      buttons: {
        cancel: 'No',
        confirm: {
          text: 'Yes',
          className: 'btn-primary'
        }
      }
    }).then((value) => {
      if (value) {
        dispatch(runFormDisablePilotWarning())
      }
    })
  }

  const getMaxTimeInMinutes = () => {
    const maxTimeType = formik.values.task_parameters.max_time_type
    const maxTime = maxTimeType === 'minutes'
      ? parseInt(formik.values.task_parameters.max_time_per_submission)
      : parseFloat(formik.values.task_parameters.max_time_per_submission)

    return Math.round(maxTime * timeMultiplyValues[maxTimeType])
  }

  const onCountryChange = (country, platform) => {
    setIsCountryChanged(true)
    handleQuoteParamsChange({platform, country})
    handleCintEligibleParamsChange(platform, country, true)
  }

  const onPlatformChange = (platform) => {
    setIsPlatformChanged(true)
    handleQuoteParamsChange({platform})
    handleCintEligibleParamsChange(platform)
  }

  const showEligibleParticipants = () => {
    return props.isQualifiersCountLoading ||
      props.isCintQualifiersCountLoading ||
      (props.isMturk && props.eligibleParticipantsCount !== null && props.eligibleParticipantsCount !== undefined) ||
      (props.isCint && props.eligibleParticipantsCountForCint !== null && props.eligibleParticipantsCountForCint !== undefined)
  }

  const handleNumberOfSubmissionsChange = () => {
    if (numberOfSubmissionsRef.current === formik.values.task_parameters.number_of_submissions) return

    numberOfSubmissionsRef.current = formik.values.task_parameters.number_of_submissions
    handleQuoteParamsChange()
    handleCintEligibleParamsChange()
  }

  const handleMaxTimePerSubmissionChange = () => {
    if (maxTimePerSubmissionRef.current === formik.values.task_parameters.max_time_per_submission) return

    maxTimePerSubmissionRef.current = formik.values.task_parameters.max_time_per_submission
    handleQuoteParamsChange()
    handleCintEligibleParamsChange()
  }

  const handleMaxTimeTypeChange = (e) => {
    const value = e.target.value
    formik.handleChange(e)
    const maxTime = formik.values.task_parameters?.max_time_per_submission
    if (value !== 'minutes' || !maxTime) return

    formik.setFieldValue('task_parameters.max_time_per_submission', parseInt(maxTime))
  }

  const handlePayPerSubmissionChange = (e, value) => {
    formik.setFieldValue(e.target.name, value)

    if (!value.match(/[0-9]/g)) return

    setIsPayPerSubmissionChanged(true)
    handleQuoteParamsChange({payPerSubmission: value})
  }

  const handlePayPerSubmissionBlur = (e) => {
    const {name, value} = e.target
    const formattedPayPerSubmission = value === '' ? '' : parseFloat(value).toFixed(2)
    formik.setFieldValue(name, formattedPayPerSubmission)
  }

  const handleExpiresAfterChange = () => {
    if (expiresAfterRef.current === formik.values.task_parameters.expires_after) return

    expiresAfterRef.current = formik.values.task_parameters.expires_after
    handleCintEligibleParamsChange()
  }

  const updateScreenerPreviousTotalRunCostRef = () => {
    if(!previousTotalRunCostRef.current && props.agree_screening_guidelines) {
      previousTotalRunCostRef.current = props.est_max_budget
    }
  }

  const handleQuoteParamsChange = ({platform, country, payPerSubmission, updatedHourlyRate} = {}) => {
    if (props.agree_screening_guidelines && !props.screeninRate)
      return

    if (props.agree_screening_guidelines && props.screeninRate && !isIncidenceRateValid)
      return

    const controller = new AbortController()
    const {signal} = controller

    if (quoteAbortController !== null)
      quoteAbortController.abort()

    setQuoteAbortController(controller)

    // getQuote API params
    const selectedPlatform = platform || props.platform
    const params = {
      platform: selectedPlatform.platform_type === 'Amazon Mechanical Turk' ? selectedPlatform.platform_type : selectedPlatform.name,
      country_name: country || formik.values.task_parameters.country_name,
      number_of_submissions: parseInt(formik.values.task_parameters.number_of_submissions),
      max_time_per_submission: getMaxTimeInMinutes(),
    }
    if (props.agree_screening_guidelines && props.screeninRate)
      params.screenin_rate = props.screeninRate
    if (props.task_parameters?.payment_per_submission && !props.hourlyRate)
      params.payment_per_submission = props.task_parameters.payment_per_submission
    if (isPayPerSubmissionChanged)
      params.payment_per_submission = formik.values.task_parameters.payment_per_submission
    if (payPerSubmission)
      params.payment_per_submission = payPerSubmission
    if (props.hourlyRate && !params.payment_per_submission)
      params.hourly_rate = props.hourlyRate

    const isParamsValid = (
      params.platform
      && params.number_of_submissions > 0
      && params.max_time_per_submission > 0
      && (props.isCint ? params.max_time_per_submission <= props.maxInterviewLength : true)
      && params.country_name !== ''
    )

    // update default max budget as previous total run cost
    updateScreenerPreviousTotalRunCostRef()

    if (isParamsValid) {
      const isPayPerSubmissionOnFocus = !!payPerSubmission
      dispatch(quoteForRunFetch(params, signal, isPayPerSubmissionOnFocus))
    } else {
      dispatch(quoteForRunReset())
    }
  }

  const handleCintEligibleParamsChange = (platform = null, country = null, excludeCountryDependentFilters = false) => {
    // Selected platform is not Cint
    if (platform && !isCintPlatform(platform.platform_type))
      return

    if (!props.isCint)
      return

    if (props.screeninRate
      && (props.screeninRate < props.minIncidenceRate || props.screeninRate > props.maxIncidenceRate)
    )
      return

    const controller = new AbortController()
    const {signal} = controller

    if (cintEligibleAbortController !== null)
      cintEligibleAbortController.abort()

    setCintEligibleAbortController(controller)

    const cint_attributes = getFormattedCintAttributes(props.cintFilters)

    // Get eligible participants count for Cint
    const selectedPlatform = platform || props.platform
    let params = {
      country_name: country || formik.values.task_parameters.country_name,
      number_of_submissions: parseInt(formik.values.task_parameters.number_of_submissions),
      max_time_per_submission: parseInt(formik.values.task_parameters.max_time_per_submission),
      expires_after: parseInt(formik.values.task_parameters.expires_after),
      gender: cint_attributes.gender,
      platform_id: selectedPlatform.id,
      collects_pii: formik.values.collects_pii,
      prescreen_data: props.prescreen_data,
      task_id: props.task_id,
    }

    if (!excludeCountryDependentFilters) {
      params = {
        ...params,
        variable_ids: getFormattedVariableIds(props.cintFilters),
        region_ids: getFormattedRegionIds(props.cintFilters),
        age_min: parseInt(cint_attributes.age_min),
        age_max: parseInt(cint_attributes.age_max),
      }
    }

    if (props.agree_screening_guidelines && props.screeninRate)
      params.incidence_rate = props.screeninRate

    const isFiltersValid = excludeCountryDependentFilters || !props.cintFilters.some(filter => filter.is_filter_valid === false)
    const isQuotasValid = excludeCountryDependentFilters || !props.cintQuotas.some(quota => quota.isValid === false)
    const isParamsValid = (
      params.number_of_submissions > 0
      && params.max_time_per_submission > 0
      && params.max_time_per_submission <= props.maxInterviewLength
      && params.expires_after > 0
      && params.expires_after <= 31
      && params.country_name !== ''
      && isFiltersValid
      && isQuotasValid
    )

    if (isParamsValid) {
      params.quota_groups = generateQuotaGroups(params, props.cintQuotas, props.cintInterlockedQuotas, excludeCountryDependentFilters)
      dispatch(runFormQualifiedWorkersCountForCintFetch(params, signal))
    } else {
      dispatch(runFormQualifiedWorkersCountForCintReset())
    }
  }

  const showTotalCost = () => (
    formik.values.task_parameters.number_of_submissions
    && formik.values.task_parameters.max_time_per_submission
    && formik.values.task_parameters.payment_per_submission
    && (props.isQuoteLoading || formattedTotalRunCost)
  )

  const handleActivityLanguageChange = (option) => {
    const activityLanguageId = option.value
    formik.setFieldValue('activity_language_id', activityLanguageId)
    validateFormAndDispatchValues({activity_language_id: activityLanguageId})

    if (props.isActivityLanguageEnglish(activityLanguageId)) {
      dispatch(runFormLoadDefaultQueryStrings())
    } else {
      dispatch(runFormResetFiltersAndQueryStrings())
    }
  }

  const createOption = (options) => {
    if (!options) return

    if (options.length && typeof options === 'object') {
      return options.map(option => ({
        label: option.label,
        value: option.id
      }))
    } else {
      const {id: value, label} = getActivityLanguageById(props.languages, options) || {}
      return {label, value}
    }
  }

  const handleChange = (e, value) => {
    formik.setFieldValue(e.target.name, value)
  }

  const expiryTimeError = () => {
    const expiresAfter = parseFloat(formik.values.task_parameters.expires_after)
    const expiresAfterTimeType = formik.values.task_parameters.expires_after_time_type
    const expiryTimeInMinutes = timeMultiplyValues[expiresAfterTimeType] * expiresAfter

    if (props.isMturk) {
      if (expiryTimeInMinutes < 30) {
        return 'Can\'t be less than 30 minutes'
      }

      if (expiresAfter > mturkMaxExpiryValues[expiresAfterTimeType]) {
        return 'Can\'t be more than 365 days'
      }
    } else if (props.isCint) {
      if (expiresAfter < 1) {
        return 'Can\'t be less than a day'
      }

      if (expiresAfter > 31) {
        return 'Can\'t be more than 31 days'
      }
    }
  }

  const estAssignmentDurationError = () => {
    const maxTimePerSubmission = parseFloat(formik.values.task_parameters.max_time_per_submission)
    const maxTimeType = formik.values.task_parameters.max_time_type
    const expiryTimeInMinutes = timeMultiplyValues[maxTimeType] * maxTimePerSubmission

    if (expiryTimeInMinutes < 1) {
      return 'Your time estimate is too low, it must be at least 1 minute'
    }

    if (props.isCint && maxTimePerSubmission > props.maxInterviewLength) {
      return `Your time estimate is too high, it must be at most ${props.maxInterviewLength} minutes`
    }
  }

  // Side effects
  useEffect(() => {
    if (!props.isLoading) validateFormAndDispatchValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.task_parameters.country_name,
    formik.values.platform_id,
    formik.values.collects_pii,
    formik.values.agree_pii,
    formik.values.task_parameters.expires_after,
    formik.values.task_parameters.expires_after_time_type,
    formik.values.task_parameters.number_of_submissions,
    formik.values.task_parameters.max_submissions_per_person,
    formik.values.task_parameters.max_time_per_submission,
    formik.values.task_parameters.max_time_type,
    formik.values.task_parameters.payment_per_submission,
  ])

  // Get Cint filters for the selected country
  useEffect(() => {
    const countryName = formik.values.task_parameters.country_name
    if (countryName && props.platform && isCintPlatform(props.platform.platform_type)) {
      formik.setFieldValue('task_parameters.max_time_type', 'minutes')
      formik.setFieldValue('task_parameters.expires_after_time_type', 'days')
      if(props.prescreenData?.ps_type === 'custom') {
        dispatch(runFormOverviewAddOrUpdatePrescreenData({}, []))
      }
      dispatch(runFormProfilingVariablesFetch(countryName, props.platform.id))
    } else if (props.isMturk && (isCountryChanged || isPlatformChanged)) {
      if (isCountryChanged)
        setIsCountryChanged(false)

      if (isPlatformChanged)
        setIsPlatformChanged(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.task_parameters.country_name,
    props.platform,
  ])

  const shouldShowBudgetChangedWarning = () => {
    return props.agree_screening_guidelines
      && previousTotalRunCostRef.current
      && props.defaultMaxBudget !== previousTotalRunCostRef.current
  }

  const updateRunFormOverview = () => {
    dispatch(runFormOverviewUpdate({
      totalRunCost: props.totalRunCost,
      formattedTotalRunCost: props.totalRunCost
    }))
  }

  const payPerSubmissionBorderClass = () => {
    if(props.hourlyRate >= 0.01 && props.hourlyRate <= 4) {
      return 'very-low-pay'
    } else if(props.hourlyRate > 4 && props.hourlyRate < 7.25){
      return 'low-pay'
    }
  }

  // Update payment per submission & total cost based on quote
  useEffect(() => {
    if (props.paymentPerSubmission && props.totalRunCost) {
      if (shouldShowBudgetChangedWarning()) {
        props.setShowBudgetChangedWarning(true)
      }
      previousTotalRunCostRef.current = props.agree_screening_guidelines ? props.defaultMaxBudget : props.totalRunCost
      formik.setFieldValue('task_parameters.payment_per_submission', props.paymentPerSubmission)
      setFormattedTotalRunCost(props.totalRunCost)
      updateRunFormOverview()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.paymentPerSubmission, props.totalRunCost, props.defaultMaxBudget])

  useEffect(() => {
    validateFormAndDispatchValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isMturk, props.agree_screening_guidelines])

  useEffect(() => {
    handleQuoteParamsChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.screeninRate,
    props.agree_screening_guidelines,
    formik.values.task_parameters.max_time_type,
  ])

  useEffect(() => {
    if (props.isCint) handleCintEligibleParamsChange()
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    props.isCint,
    props.cintFilters,
    props.cint_profiling_variables,
    props.screeninRate,
    props.agree_screening_guidelines,
    props.cintQuotas,
    formik.values.collects_pii,
    props.prescreen_data
  ])
  /* eslint-enable */

  useEffect(() => {
    if (props.isCint && props.maxInterviewLength && validateOnce) {
      validateFormAndDispatchValues()
      setValidateOnce(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.maxInterviewLength, props.isCint, validateOnce])

  const gotoParticipantsTab = () => {
    sendGAEvent(GA_EVENTS.CLICK_TARGET_AUDIENCE, {positly_user_id: props.currentUser.uid})
    props.setActiveTab(TABS.PARTICIPANTS)
  }

  const gotoConfirmationTab = () => {
    sendGAEvent(GA_EVENTS.CLICK_FINAL_STEPS, {positly_user_id: props.currentUser.uid})
    props.setActiveTab(TABS.CONFIRMATION)
  }

  // UI template
  return (
    <div
      className={`create ${props.isTabActive(TABS.DETAILS) ? 'active' : 'hide'}`}
      name="runFormDetails"
      id="runFormDetailsTab"
    >
      <div className="pb-3">
        <div className="row run-form">
          {/* Panel - Left */}
          <div className="col-lg-6 col-md-12 left">
            {/* GuidedTrack Promotion */}
            <GuidedTrackPromo className="box"/>

            {/* Run Details - Name, Country and Platform */}
            <div className="box">
              <div className="form-group">
                <label>
                  <h2 className="text-required">Run name</h2>
                </label>
                <Input
                  id="name"
                  type="text"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={handleBlur}
                  className="form-control"
                  maxLength={titleLimit}
                  tabIndex="1"
                />
              </div>

              <CountryPlatformSelect
                onCountryChange={onCountryChange}
                onPlatformChange={onPlatformChange}
                countryFieldName="task_parameters.country_name"
                countryFieldValue={formik.values.task_parameters.country_name}
                platformFieldName="platform_id"
                platformFieldValue={formik.values.platform_id}
                setMaxInterviewLength={props.setMaxInterviewLength}
                setMinIncidenceRate={props.setMinIncidenceRate}
                setMaxIncidenceRate={props.setMaxIncidenceRate}
                availablePlatforms={availablePlatforms}
                setAvailablePlatforms={setAvailablePlatforms}
                setOldCountryName={props.setOldCountryname}
                formik={formik}
                setMinAgeForCountry={props.setMinAgeForCountry}
              />
            </div>

            {/* Platform selection */}
            <PlatformSelect
              fieldName="platform_id"
              fieldValue={formik.values.platform_id}
              availablePlatforms={availablePlatforms}
              formik={formik}
              onPlatformChange={onPlatformChange}
              defaultPlatformId={props.defaultPlatformId}
              setDefaultPlatformId={props.setDefaultPlatformId}
            />
          </div>

          {/* Panel - Right */}
          <div className="col-lg-6 col-md-12 right">
            {/* Run Details - Language, PII consent and Expiry */}
            <div className="box run-completion-box">
              <ActivityLanguageSelect
                value={createOption(formik.values.activity_language_id)}
                onChange={handleActivityLanguageChange}
                options={createOption(props.languages)}
                showTooltip={true}
                className="form-group"
              />

              <div className="wrap_info mt-2 pt-2">
                <div className="radio-checkbox">
                  <label className="d-flex">
                    <input
                      name="collects_pii"
                      className="checkbox"
                      checked={formik.values.collects_pii}
                      onChange={formik.handleChange}
                      tabIndex="3"
                      type="checkbox"
                    />
                    <span className="checkbox-custom checkbox-long-label"/>
                    <span className="label">
                      This study will collect personally identifiable information
                      (such as a participant's email address, last name or phone number)
                    </span>
                  </label>
                </div>
              </div>
              {formik.values.collects_pii && (
                <div className="notice notice-wo-icon">
                  <span>
                    <span className="text-bold">Important:</span>&nbsp;
                    Researchers must not make PII a requirement for survey completion or participant compensation.
                    Requests for PII must be optional and skippable.
                  </span>
                </div>
              )}
              {formik.values.collects_pii && (
                <div className="wrap_info mt-3">
                  <div className="radio-checkbox">
                    <label>
                      <input
                        name="agree_pii"
                        className="checkbox"
                        checked={formik.values.agree_pii}
                        onChange={formik.handleChange}
                        tabIndex="3"
                        type="checkbox"
                      />
                      <span className="checkbox-custom"/>
                      <span className={`label text-required ${!formik.values.agree_pii ? 'text-danger' : ''}`}>
                        I agree to adhere to&nbsp;
                        <a
                          className="pii-link"
                          href="https://www.positly.com/support/personally-identifiable-information-guidelines/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Positly's PII guidelines
                        </a>
                      </span>
                    </label>
                  </div>
                </div>
              )}

              <div className="wrap_form">
                <div className="wrap_left">
                  <div className="form-group">
                    <h2>
                      <label className="text-required">Take down run after</label>
                    </h2>
                    <Input
                      name="task_parameters.expires_after"
                      tabIndex="4"
                      type="text"
                      onKeyUp={handleExpiresAfterChange}
                      onChange={handleChange}
                      value={formik.values.task_parameters.expires_after}
                      fractionDigits={2}
                      allowOnlyNumbers
                    />
                  </div>
                </div>
                <div className="wrap_right">
                  <h2 className="invisible">Time type</h2>
                  <select
                    name="task_parameters.expires_after_time_type"
                    onChange={formik.handleChange}
                    value={formik.values.task_parameters.expires_after_time_type}
                    tabIndex="5"
                  >
                    {props.isCint
                      ? (
                        <option value="days">Days</option>
                      )
                      : (
                        <>
                          <option value="minutes">Minutes</option>
                          <option value="hours">Hours</option>
                          <option value="days">Days</option>
                          <option value="weeks">Weeks</option>
                        </>
                      )}
                  </select>
                </div>
              </div>
              {expiryTimeError() && (
                <p className="text-red-soft po-text mt-0" style={{lineHeight: '1.1rem'}}>
                  {expiryTimeError()}
                </p>
              )}
            </div>

            {/* Completions Details */}
            <div className="box run-completion-box">
              <div className="div wrap_title run-completion-title">
                <h2 className="big">Completions</h2>
                {(
                  !hidePilotWarning && props.showPilotWarning && formik.values.task_parameters.number_of_submissions > 20
                  && (props.runsCount === 0 || (props.taskRunsCount === 1 && props.runStatus === 'draft') || props.userRunsCount === 0)
                ) && (
                  <div className="notice mr-0">
                    <span>
                      We recommend 20 or fewer participants to&nbsp;
                      <a
                        className="text-info"
                        href="https://www.positly.com/support/why-should-i-pilot-my-studies/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        pilot your run
                      </a>
                    </span>
                    <span className="ml-2 pilot-close" onClick={disablePilotWarning}>×</span>
                  </div>
                )}
              </div>
              <div className="wrap_form">
                <div className={`wrap_left ${!props.isMturk ? 'no-margin' : ''}`}>
                  <h2 className="text-required">
                    How many times do you want your activity completed?
                  </h2>
                  <div className="form-group run-detail">
                    <div className="icon zindex-0"/>
                    <Input
                      name="task_parameters.number_of_submissions"
                      type="text"
                      className="form-control"
                      tabIndex="6"
                      value={formik.values.task_parameters.number_of_submissions}
                      onKeyUp={handleNumberOfSubmissionsChange}
                      onChange={handleChange}
                      allowOnlyNumbers
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  {formik.touched.task_parameters?.number_of_submissions && formik.errors.task_parameters?.number_of_submissions ? (
                    <div className="po-text text-red-soft mt-1 lh-1">
                      {formik.errors.task_parameters.number_of_submissions}
                    </div>
                  ) : null}
                </div>
                {props.isMturk && (
                  <div className="wrap_right">
                    <h2 className="text-required">
                      How many times can each participant complete your activity?
                    </h2>
                    <div className="form-group run-detail">
                      <div className="icon max zindex-0"/>
                      <Input
                        name="task_parameters.max_submissions_per_person"
                        type="text"
                        className="form-control"
                        tabIndex="7"
                        value={formik.values.task_parameters.max_submissions_per_person}
                        onChange={handleChange}
                        allowOnlyNumbers
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    {formik.touched.task_parameters?.max_submissions_per_person && formik.errors.task_parameters?.max_submissions_per_person ? (
                      <div className="po-text text-red-soft mt-1 lh-1">
                        {formik.errors.task_parameters.max_submissions_per_person}
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
              <div className="wrap_form">
                <div className="wrap_left">
                  <h2 className="text-required">
                    Estimated time to complete activity
                  </h2>
                  <div className="form-group run-detail">
                    <div className="wrap_select est-time">
                      <div className="icon time_activity zindex-0"/>
                      <Input
                        name="task_parameters.max_time_per_submission"
                        type="text"
                        className="form-control max-time-per-submission-input"
                        tabIndex="8"
                        value={formik.values.task_parameters.max_time_per_submission}
                        onKeyUp={handleMaxTimePerSubmissionChange}
                        onChange={handleChange}
                        onBlur={handleMaxTimePerSubmissionChange}
                        fractionDigits={2}
                        allowOnlyNumbers
                        preventDecimals={formik.values.task_parameters.max_time_type === 'minutes'}
                      />
                      <select
                        name="task_parameters.max_time_type"
                        style={{borderLeft: 'none'}}
                        tabIndex="9"
                        value={formik.values.task_parameters.max_time_type}
                        onChange={handleMaxTimeTypeChange}
                      >
                        <option value="minutes">Minutes</option>
                        {props.isMturk && (
                          <>
                            <option value="hours">Hours</option>
                            <option value="days">Days</option>
                            <option value="weeks">Weeks</option>
                          </>
                        )}
                      </select>
                    </div>
                  </div>
                  {estAssignmentDurationError() && (
                    <p className="text-red-soft po-text mt-2" style={{lineHeight: '1.1rem'}}>
                      {estAssignmentDurationError()}
                    </p>
                  )}

                </div>
                {(
                  <div className="wrap_right">
                    <h2 className="text-required">Payment per activity completion</h2>
                    <div className={`form-group run-detail ${payPerSubmissionBorderClass()}`}>
                      <div className="currency zindex-0 ">USD $</div>
                      <Input
                        name="task_parameters.payment_per_submission"
                        type="number"
                        className="form-control currency_input"
                        tabIndex="10"
                        onChange={handlePayPerSubmissionChange}
                        disabled={!props.isMturk}
                        value={formik.values.task_parameters.payment_per_submission}
                        step="0.01"
                        min="0.01"
                        fractionDigits={2}
                        onBlur={handlePayPerSubmissionBlur}
                      />
                    </div>
                    {formik.values.task_parameters.payment_per_submission !== ''
                      && formik.values.task_parameters.payment_per_submission < 0.01
                      && (
                        <p className="result">
                          <strong className="text-orange">
                            Minimum payment per activity completion is $0.01
                          </strong>
                        </p>
                      )}
                    {(
                      formik.values.task_parameters.payment_per_submission
                      && formik.values.task_parameters.max_time_per_submission
                      && props.hourlyRate >= 0.01
                      && props.hourlyRate <= 4
                      && props.isMturk
                    ) ? (
                        <div className="result">
                          <strong className="text-orange mr-1">
                            Pay is very low
                          </strong>
                          <i className="fas fa-info-circle font-size-16 text-orange cursor-pointer"
                             data-tip="Warning: pay is very low, which may cause complaints, reduce the quality of participants, and make the run take a lot longer."
                             data-for="very-low-pay-warning" />
                          <ReactTooltip
                              className="react-tooltip-po tooltip-light"
                              id="very-low-pay-warning"
                              place="bottom"
                              offset={{bottom: 10}}
                              effect="solid"
                              arrowColor="#fff"
                              borderColor="#fff"
                          />
                        </div>
                    ) : null}
                    {(
                        formik.values.task_parameters.payment_per_submission
                        && formik.values.task_parameters.max_time_per_submission
                        && props.hourlyRate > 4
                      && props.hourlyRate < 7.25
                      && props.isMturk
                    ) ? (
                        <div className="result">
                          <strong className="text-dark-yellow mr-1">
                            Pay is below $7.25/hr
                          </strong>
                          <i className="fas fa-info-circle font-size-16 text-dark-yellow cursor-pointer"
                             data-tip="Warning: pay is low, which may cause the run to take longer and lower the quality of participation."
                             data-for="low-pay-warning"/>
                          <ReactTooltip
                              className="react-tooltip-po tooltip-light"
                              place="bottom"
                              offset={{bottom: 10}}
                              id="low-pay-warning"
                              effect="solid"
                              arrowColor="#fff"
                          />
                        </div>
                    ) : null}
                    {(
                        formik.values.task_parameters.payment_per_submission
                        && formik.values.task_parameters.max_time_per_submission
                        && props.isMturk
                        && props.hourlyRate > 0
                    ) ? (
                        <p className="result">Estimated pay rate: <Currency value={props.hourlyRate}/>/hr</p>
                    ) : null}
                  </div>
                )}
              </div>
              <div className="line_wrap mt-3">
                {showTotalCost() > 0 && (
                  <div className="line">
                    <div className="icon total-cost-icon"/>
                    {props.isQuoteLoading ? (
                      <div className="wrap_text">
                        <Spinner/>
                      </div>
                    ) : (
                      <div className="wrap_text">
                        <div className="title">
                          <Currency
                            value={props.agree_screening_guidelines ? props.est_max_budget : props.quote.totalRunCost}
                          />
                        </div>
                        <div className="desc">
                          {props.agree_screening_guidelines ? 'Max budget ' : 'Total cost '}
                          <div className="d-inline-block po-tooltip participants-attributes-tooltip">
                            <span className="po-tooltiptext po-tooltip-position">Inclusive of all fees.</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {showEligibleParticipants() && (
                  <div className="line">
                    <div className="icon eligible-participants-icon"/>
                    <div className="wrap_text">
                      {props.isMturk && (props.isQualifiersCountLoading || props.eligibleParticipantsCount === undefined
                          ? <Spinner/>
                          : (
                            <>
                              <div className="title">
                                {new Intl.NumberFormat('en-US').format(props.eligibleParticipantsCount)}
                              </div>
                              <div className="desc">Eligible participants</div>
                            </>
                          )
                      )}

                      {props.isCint && (props.isCintQualifiersCountLoading || props.eligibleParticipantsCountForCint === undefined
                          ? <Spinner/>
                          : (
                            <>
                              <div className="title">
                                {new Intl.NumberFormat('en-US').format(props.eligibleParticipantsCountForCint)}
                              </div>
                              <div className="desc">Eligible participants</div>
                            </>
                          )
                      )}
                    </div>
                  </div>
                )}

                {props.agree_screening_guidelines && props.est_max_budget
                  && props.showBudgetChangedWarning && formik.values.task_parameters.payment_per_submission && (
                    <div className="text-red-soft po-text mt-2 w-100 row" style={{lineHeight: '1.1rem'}}>
                      <div className="col-lg-6 col-sm-12">
                        Your cost estimate has changed. Please update your screening setup.
                      </div>
                    </div>
                  )
                }

                <LowEligibleParticipantsWarning
                  isLoading={props.isQualifiersCountLoading || props.isCintQualifiersCountLoading}
                  isMturk={props.isMturk}
                  eligibleParticipantsCountForCint={props.eligibleParticipantsCountForCint}
                  eligibleParticipantsCountForMturk={props.eligibleParticipantsCount}
                  expiresAfter={formik.values.task_parameters.expires_after}
                  numberOfSubmissions={formik.values.task_parameters.number_of_submissions}
                  maxTimePerSubmission={formik.values.task_parameters.max_time_per_submission}
                  maxInterviewLength={props.maxInterviewLength}
                  className="mt-4"
                />
              </div>
            </div>

            <QualityFilter isMturk={props.isMturk} isCint={props.isCint}/>

            <div className="row run-form-buttons">
              <div className="col">
                <div className="wrap_link wrap_link_filter d-flex">
                  <CancelButton
                    disabled={props.isSaving || props.isPublishing}
                    onClick={props.handleCancel}
                  />
                  <SaveAsDraftButton
                    isSaving={props.isSaving}
                    onClick={props.saveAsDraft}
                    disabled={props.isSaving || props.isPublishing}
                    isMturk={props.isMturk}
                    setShowSpendingLimitWarning={props.setShowSpendingLimitWarning}
                    showBudgetChangedWarning={props.showBudgetChangedWarning}
                    isAnyQuotaInvalid={props.isAnyQuotaInvalid}
                  />
                  <button
                    className="btn btn-primary no-border clickable transition-0"
                    tabIndex="13"
                    onClick={gotoParticipantsTab}
                    disabled={props.isSaving || props.isPublishing}
                  >
                    {TABS.PARTICIPANTS.navigationText}
                    <i className="ml-2 icon ion-android-arrow-forward"/>
                  </button>
                  {props.runId && props.isDetailsFormValid && props.isParticipantsFormValid && props.isActivityFormValid && (
                    <button
                      className="btn btn-primary no-border clickable transition-0"
                      tabIndex="13"
                      onClick={gotoConfirmationTab}
                      disabled={props.isSaving || props.isPublishing}
                    >
                      {TABS.CONFIRMATION.navigationText}
                      <i className="ml-2 icon ion-android-arrow-forward"/>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  if (!state.runForm) {
    return {isLoading: true, task_parameters: {}, platforms: []}
  }

  return {
    task_id: state.runForm.task_id || '',
    name: state.runForm.name || '',
    platform_id: state.runForm.platform_id || '',
    collects_pii: state.runForm.collects_pii || false,
    task_parameters: {
      country_name: state.runForm.task_parameters.country_name || '',
      expires_after: state.runForm.task_parameters.expires_after || '',
      expires_after_time_type: state.runForm.task_parameters.expires_after_time_type || '',
      number_of_submissions: state.runForm.task_parameters.number_of_submissions || '',
      max_submissions_per_person: state.runForm.task_parameters.max_submissions_per_person || 1,
      max_time_per_submission: state.runForm.task_parameters.max_time_per_submission || '',
      max_time_type: state.runForm.task_parameters.max_time_type || 'minutes',
      payment_currency: state.runForm.task_parameters.payment_currency || '$',
      payment_per_submission: state.runForm.task_parameters.payment_per_submission || '',
    },
    platforms: state.platformList.platforms || [],
    isQuoteLoading: state.runForm.quote?.isLoading,
    paymentPerSubmission: state.runForm.quote?.paymentPerSubmission,
    hourlyRate: state.runForm.quote?.hourlyRate,
    totalRunCost: state.runForm.quote?.totalRunCost,
    defaultHourlyRate: state.runForm.user?.default_hourly_rate || 7.5,
    showPilotWarning: state.runForm.user?.show_pilot_warning || false,
    runsCount: state.runForm.runs_count,
    taskRunsCount: state.runForm.task_runs_count,
    userRunsCount: state.runForm.user_runs_count,
    runStatus: state.runForm.status,
    isQualifiersCountLoading: state.runForm.qualifiersCount?.isLoading,
    eligibleParticipantsCount: state.runForm.qualifiersCount?.total,
    isCintQualifiersCountLoading: state.runForm.cintQualifiersCount?.isLoading,
    eligibleParticipantsCountForCint: state.runForm.cintQualifiersCount?.total,
    cintFilters: state.runFormOverview.cint_filters,
    cint_profiling_variables: state.runForm?.cint_profiling_variables,
    platform: state.runFormOverview.platform,
    languages: state.activityLanguages?.languages,
    currentUser: state.currentUser,
    activity_language_id: state.runForm.activity_language_id,
    cint_incidence_rate: state.runFormOverview.task_parameters.est_incidence_rate,
    agree_screening_guidelines: state.runFormOverview.agree_screening_guidelines,
    est_max_budget: state.runFormOverview.task_parameters.est_max_budget,
    quote: state.runForm.quote,
    cintQuotas: state.runFormOverview.cint_quotas,
    cintInterlockedQuotas: state.runFormOverview.cint_interlocked_quotas,
    defaultMaxBudget: state.runForm.quote?.defaultMaxBudget,
    prescreenData: state.runFormOverview.prescreen_data
  }
}

export default connect(mapStateToProps)(RunFormDetailsTab)
