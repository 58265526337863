import React from 'react'

const HelpMenuItem = () => {
  return (
    <li>
      <a href="https://positly.com/support" target="_blank" rel="noopener noreferrer">
        Help
      </a>
    </li>
  )
}

export default HelpMenuItem
