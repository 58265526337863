export const LOCAL_STORAGE_KEYS = {
  FEEDBACK_LINK_VERIFICATION_ID: 'feedback_link_verification_id',
}

export const LINK_VERIFICATION_TYPES = Object.freeze({
  COMPLETION_LINK: 'completion_link',
  SCREEN_IN_LINK: 'screen_in_link',
  SCREEN_OUT_LINK: 'screen_out_link'
})

export const SCREENOUT_CONTEXTS = Object.freeze({
  DECLINED: 'declined',
  DECLINED_TIMEOUT: 'declined_timeout',
  ACTIVITY_FULL: 'activity_full',
  ACTIVITY_TIMEOUT: 'activity_timeout',
  SCREENOUT: 'screenout'
})

export const MAIN_ACTIVITY_FEEDBACK = Object.freeze(
  {
    LINK_MISSING_LABEL: 'I did not receive a Positly feedback survey after the bonus activity',
    LINK_MISSING_VALUE: 'completion_link_missing',
    ENCOUNTERED_A_PROBLEM_LABEL: 'I encountered a problem with the bonus activity',
    ENCOUNTERED_A_PROBLEM_VALUE: 'encountered_a_problem'
  }
)

export const MTURK_PLATFORMS = Object.freeze(['Positly', 'Amazon Mechanical Turk'])

export const CINT_PLATFORMS = Object.freeze(['Cint', 'Cint Sandbox'])

export const isMturkPlatform = (platformType) => MTURK_PLATFORMS.includes(platformType)

export const isCintPlatform = (platformType) => CINT_PLATFORMS.includes(platformType)

export const LINKEDIN_CONVERSION_IDS = Object.freeze({
  SIGN_UP: '12777364',
  PROJECT_CREATED: '12777372',
  FIRST_PROJECT_CREATED: '12931012',
  RUN_PUBLISHED: '12777380',
  FIRST_RUN_PUBLISHED: '12931020',
  CLICK_CREATE_WITH_GT: '12931028',
  CLICK_GT_PROMO: '12931036'
})

export const UTM_SOURCES = Object.freeze({
  LINKEDIN: 'LinkedIn',
})

export const DEFAULT_QUALITY_FILTER = Object.freeze({
  is_one_worker_per_ip: true,
  is_isp_check_enabled: true,
  is_hq_participants_only: true,
  is_attention_check_enabled: true,
  is_country_check_enabled: true,
  is_very_hq_enabled: false,
  minimum_platform_approval_rate: 99,
  minimum_platform_tasks_completed: 500,
  minimum_positly_pass_rate: 1,
  minimum_positly_approvals: 0
})

export const FILTER_TYPE = Object.freeze({
  DEMOGRAPHIC: 'demographic',
  CUSTOM_ATTRIBUTE: 'custom_attribute'
})

export const GENDER = Object.freeze({male: {id: 1, name: 'Male'}, female: {id: 2, name: 'Female'}})
export const VALID_UID_REGEX = /^p?[a-f0-9]{8}$/

export const CINT_REGION_CATEGORY_NAME = 'Location, Region, City'
