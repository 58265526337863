import React from 'react'
import {useSelector} from 'react-redux'

import {LINKEDIN_CONVERSION_IDS} from '../utils/constants'

import positlyLogo from '../images/positly.png'
import shuffleIcon from '../images/squiggles.png'
import guidedTrackLogo from '../images/guided-track.png'
import {GA_EVENTS, sendGAEvent, triggerLinkedinInsightEvent} from '../utils/helper'

const GuidedTrackPromo = ({className = ''}) => {
  const {uid: positly_user_id, utm_parameters} = useSelector(state => state.currentUser || {})

  const clickCreateWithGuidedTrack = () => {
    sendGAEvent(GA_EVENTS.CLICK_CREATE_WITH_GT, {positly_user_id})
    triggerLinkedinInsightEvent(LINKEDIN_CONVERSION_IDS.CLICK_CREATE_WITH_GT, utm_parameters)
  }

  return (
    <div className={`gt-promo ${className}`}>
      <div className="row">
        <div className="col-md-9 gt-positly-desc">
          <h2 className="font-size-16 pr-3 mb-2">
            Have you built your activity or survey yet?<br/>
            Try using GuidedTrack for free! (Optional)
          </h2>
          <p className="po-text">
            While Positly is compatible with many survey tools, it effortlessly integrates with GuidedTrack for
            instant setup.
          </p>
          <p className="po-text mt-2">
            No extra configuration needed!
          </p>
          <a
            className="btn btn-primary no-border clickable d-inline-flex mt-3"
            href="https://www.guidedtrack.com?utm_source=cta&utm_medium=positly_website&utm_campaign=create_with_guidedtrack"
            target="_blank"
            rel="noreferrer"
            onClick={clickCreateWithGuidedTrack}
          >
            Create with GuidedTrack
          </a>
        </div>
        <div className="col-md-3 gt-positly-logo">
          <img src={guidedTrackLogo} alt="GuidedTrack" width={70}/>
          <img src={shuffleIcon} alt="Integration" width={65} className="my-3 py-1 mx-4"/>
          <img src={positlyLogo} alt="Positly" width={60}/>
        </div>
      </div>
    </div>
  )
}

export default GuidedTrackPromo
