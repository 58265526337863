import React from 'react'

const ManagedServicesMenuItem = () => {
  return (
    <li>
      <a
        href="https://pages.guidedtrack.com/positly-lp-managed-services/"
        target="_blank"
      >
        Managed Services
      </a>
    </li>
  )
}

export default ManagedServicesMenuItem;
