import React, {useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {isEqual} from 'lodash'

import {allowOnlyNumbers, preventPaste} from '../../common/Helpers'
import {ageFilterForCint, handleScrollToQuota} from './RunUtils'

const DemographicNumericFilter = (props) => {
  // Variables
  const [from, setFrom] = useState(props.filter.from)
  const [to, setTo] = useState(props.filter.to)

  const filterId = props.filter.id
  const quotaId = `quota_${filterId}`
  const cintQuotas = useSelector(state => state.runFormOverview.cint_quotas) || []
  const quota = cintQuotas.find(quota => quota.id === filterId)

  const filterRef = useRef(null)
  const quotaRef = useRef(null)

  const className = `box gender education pb-3 custom-filter ${props.className || ''}`

  useEffect(() => {
    if (!filterRef.current) return

    filterRef.current.classList.add('highlight-filter')
    setTimeout(() => {
      if (!filterRef.current) return

      filterRef.current.classList.remove('highlight-filter')
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterRef.current])

  // Init
  useEffect(() => {
    const isValid = validateFilter()
    let updatedFilter = {...props.filter, is_filter_valid: isValid}
    updateFilter(updatedFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (e) => {
    if (e.target.name === 'from')
      setFrom(parseInt(e.target.value))
    else
      setTo(parseInt(e.target.value))
  }

  const handleBlur = () => {
    const isValid = validateFilter()
    let updatedFilter = {...props.filter, from, to, is_filter_valid: isValid}
    updateFilter(updatedFilter)
  }

  // Private methods
  const validateFilter = () => {
    // TODO: validate filter
    if ((!from && from !== 0) || (!to && to !== 0))
      return false

    if (from < props.filter.min_value || from > props.filter.max_value)
      return false

    if (to < props.filter.min_value || to > props.filter.max_value)
      return false

    if (from > to)
      return false

    return true
  }

  const removeFilter = (filter) => {
    if (!filterRef.current) return

    filterRef.current.classList.add('remove-filter')
    setTimeout(() => {
      if (filter.id && filter.id.includes('cint')) {
        props.removeFilter(filter.id)
      } else {
        props.removeFilter('demographic', filter.name)
      }
    }, 210)
  }

  const updateFilter = (filter) => {
    if (filter.id && filter.id.includes('cint')) {
      props.updateFilter(filter)
    } else {
      props.updateFilter('demographic', filter)
    }
  }

  const formattedQuestionText = (filter) => {
    if (filter.id && filter.id.includes('cint')) {
      return filter.question_text
    } else {
      return 'Based on the question: ' + filter.question_text
    }
  }

  const getInvalidRangeMessage = () => {
    if(props.filter.id === ageFilterForCint.id){
      return `Valid age range is ${props.filter.min_value} to ${props.filter.max_value}`
    } else {
      return `Valid range is ${props.filter.min_value} to ${props.filter.max_value}`
    }
  }

  const getUnder18Warning = () => {
    if(props.filter.id === ageFilterForCint.id && from < 18 && from >= props.filter.min_value) {
      return <span>
          Be sure to follow all relevant rules and regulations regarding running studies with participants under the age of 18.
        </span>
    } else {
      return null
    }
  }

  const getErrorMessage = () => {
    if ((!from && from !== 0)) {
      return 'From value is invalid';
    }

    if ((!to && to !== 0)) {
      return 'To value is invalid';
    }

    if (from < props.filter.min_value || to > props.filter.max_value || from > to || to < from) {
      return getInvalidRangeMessage()
    }
  }

  const errorMessage = getErrorMessage()
  const warningMessage = getUnder18Warning()

  useEffect(() => {
    const isQuotaModified = !isEqual(quota, quotaRef.current)
    if (!quota || !isQuotaModified) return

    quotaRef.current = {from: quota.from, to: quota.to}

    setFrom(quota.from)
    setTo(quota.to)
    const isValid = validateFilter()
    let updatedFilter = {...props.filter, from: quota.from, to: quota.to, is_filter_valid: isValid}
    updateFilter(updatedFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quota])


  // UI template
  return (
    <div ref={filterRef} className={className}>
      <h2 className="big mb-2">
        {props.filter.filter_label}{' '}
        <span className="float-right">
          <i
            className="ion-close cursor-pointer"
            onClick={() => removeFilter(props.filter)}
          />
        </span>
      </h2>

      {quota ? (
        <div className="notice notice-border mb-2">
          <span>
            This filter is now being overwritten by a quota. You can configure the quota{' '}
            <a
              className="cursor-pointer text-underline text-dark"
              href="/"
              onClick={handleScrollToQuota}
              data-quota-id={quotaId}
            >
              here
            </a>
            .
          </span>
        </div>
      ) : null}

      <div className="wrap_info_form help-text">
        <span>{formattedQuestionText(props.filter)}</span>
      </div>

      <div className="wrap_form">
        <div className="wrap_left">
          <h2 className="mb-2 text-required" style={{fontSize: '16px'}}>
            From
          </h2>
          <div className="form-group">
            <input
              name="from"
              required
              type="number"
              min={props.filter.min_value}
              max={props.filter.max_value}
              value={from}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyPress={allowOnlyNumbers}
              onPaste={preventPaste}
              placeholder="From"
              disabled={quota}
              className="mb-1"
            />
          </div>
        </div>
        <div className="wrap_right">
          <h2 className="mb-2 text-required" style={{fontSize: '16px'}}>
            To
          </h2>
          <div className="form-group">
            <input
              name="to"
              required
              type="number"
              min={props.filter.min_value}
              max={props.filter.max_value}
              value={to}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyPress={allowOnlyNumbers}
              onPaste={preventPaste}
              placeholder="To"
              disabled={quota}
              className="mb-1"
            />
          </div>
        </div>
      </div>
      {errorMessage && (
        <p className="text-red po-text">
          {errorMessage}
        </p>
      )}

      {warningMessage && (
        <div className="notice highlight-box ml-0 mt-2">
          <span>
            Warning: {warningMessage}
          </span>
        </div>
      )}
    </div>
  )
}

export default DemographicNumericFilter
