import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {cloneDeep, isEqual, sortBy} from 'lodash'

import QuotaListItem from './QuotaListItem'
import QuotaError from './QuotaError'
import QuotaTotal from './QuotaTotal'

import {getEvenDistributionForQuota} from './quotaUtils'

import {cintQuotaAddOrUpdate, cintQuotaRemove, getCensusData} from '../../../../redux/actions'
import Spinner from '../../../common/Spinner'
import {genderFilterForCint} from '../RunUtils'
import {CINT_REGION_CATEGORY_NAME} from "../../../../utils/constants";

const Quota = ({quota, className = '', removeQuota, setActiveTab, hasCensusData}) => {
  const quotaId = quota.id
  const options = quota.variables
  const [quotaOptions, setQuotaOptions] = useState(null)
  const [total, setTotal] = useState({
    percent: 0,
    participants: 0
  })
  const [showCensusSource, setShowCensusSource] = useState(false)
  const [isQuotaOptionModified, setIsQuotaOptionModified] = useState(false)

  const [shouldUnshiftArray, setShouldUnshiftArray] = useState(options && options.length && options[0].percent >= 0)

  const quotaRef = useRef(null)
  const quotaOptionsRef = useRef(null)
  const dispatch = useDispatch()

  const number_of_submissions = useSelector(state => state.runFormOverview.task_parameters.number_of_submissions)
  const numberOfSubmissions = number_of_submissions ? parseInt(number_of_submissions) : 0

  const cintFilters = useSelector(state => state.runFormOverview.cint_filters) || []
  const filter = cintFilters.find(filter => filter.id === quotaId)
  const filterOptions = filter
    ? filter.id === 'cintGender' ? filter.filter_options : filter.variables
    : []

  const interlockedQuotas = useSelector(state => state.runFormOverview.cint_interlocked_quotas) || []
  const isQuotaInterlocked = interlockedQuotas.includes(String(quotaId))

  const quotaClassName = `box gender education custom-filter ${className} ${isQuotaInterlocked ? 'interlocked-quota' : ''}`
  const isTotalPercentInvalid = numberOfSubmissions && Math.round(total.percent) !== 100
  const isTotalParticipantsInvalid = numberOfSubmissions && total.participants !== numberOfSubmissions
  const country_name = useSelector(state => state.runFormOverview.task_parameters.country_name)
  const isGenderCensusLoading = useSelector(state => state.censusData?.isGenderCensusLoading) || false
  const cintQuotas = useSelector(state => state.runFormOverview.cint_quotas) || []
  const currentCensusParams = useRef(null)

  const handleRemoveQuota = () => {
    dispatch(cintQuotaRemove(quotaId))

    quotaRef.current.classList.add('remove-filter')
    setTimeout(() => {
      removeQuota(quotaId)
    }, 210)
  }

  const handleOptionChange = (optionId, percent, participants) => {
    setQuotaOptions(prevState => prevState.map(option => {
      if (option.id !== optionId) return option

      return {
        ...option,
        percent,
        participants
      }
    }))
  }

  const handleResetQuota = () => {
    setShowCensusSource(false)
    setIsQuotaOptionModified(false)
    setQuotaOptions(() => getEvenDistributionForQuota(options, numberOfSubmissions, true, filterOptions))
  }

  const requestCensusData = (isReload = false) => {
    if (quotaOptions) {
      const censusGroups = []
      const cintAgeFilter = cintFilters.find(filter => filter.id === 'cintAge')
      const cintAgeQuota = cintAgeFilter ? cintAgeFilter : cintQuotas.find(quota => quota.id === 'cintAge')
      const minAge = cintAgeQuota ? cintAgeQuota.from : 18
      const maxAge = cintAgeQuota ? cintAgeQuota.to : 99

      quotaOptions.forEach(option => {
        censusGroups.push({
          min_age: minAge,
          max_age: maxAge,
          gender: option.id
        })
      })

      const newCensusParams = {country_name: country_name, census_groups: censusGroups}

      if (isReload || !isEqual(newCensusParams, currentCensusParams.current)) {
        currentCensusParams.current = newCensusParams
        dispatch(getCensusData(newCensusParams, {numberOfSubmissions: numberOfSubmissions, isGenderOnly: true}))
        setShowCensusSource(true)
      }
    }
  }

  const reloadCensusData = () => {
    requestCensusData(true)
  }

  const formattedQuestionText = (quota) => {
    if (quota.categoryName === CINT_REGION_CATEGORY_NAME) {
      return ''
    } else {
      return 'Based on the question: ' + quota.text
    }
  }

  useEffect(() => {
    if (!options) return

    const sortedOptions = quota.categoryName === CINT_REGION_CATEGORY_NAME ? sortBy(options, 'name') : options
    setQuotaOptions(() => getEvenDistributionForQuota(sortedOptions, numberOfSubmissions, shouldUnshiftArray, filterOptions))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfSubmissions, options])

  useEffect(() => {
    const isQuotaModified = !isEqual(quotaOptions, quotaOptionsRef.current)
    if (!quotaOptions || !isQuotaModified) return

    quotaOptionsRef.current = cloneDeep(quotaOptions)

    const quotaTotal = quotaOptions.reduce((pv, cv) => ({
      percent: pv.percent + cv.percent,
      participants: pv.participants + cv.participants
    }), {percent: 0, participants: 0})

    setTotal(quotaTotal)

    const isQuotaValid = numberOfSubmissions && Math.round(quotaTotal.percent) === 100
      && quotaTotal.participants === numberOfSubmissions
    dispatch(cintQuotaAddOrUpdate({
      id: quotaId,
      name: quotaId === genderFilterForCint.id ? 'Gender' : quota.name,
      options: quotaOptions,
      isValid: isQuotaValid
    }, shouldUnshiftArray))
    setShouldUnshiftArray(false)

    if (quotaId === 'cintGender' && hasCensusData) {
      requestCensusData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotaOptions])

  useEffect(() => {
    if (!quotaRef.current) return

    quotaRef.current.classList.add('highlight-filter')

    setTimeout(() => {
      if (!quotaRef.current) return

      quotaRef.current.classList.remove('highlight-filter')
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotaRef.current])

  useEffect(() => {
    if(quota && quota.options){
      const newOptions = [...quota.options]
      const notDistributedOptions = newOptions.filter(newOption => isNaN(newOption.participants))

      if(notDistributedOptions.length > 0){
        setQuotaOptions(() => getEvenDistributionForQuota(quota.options, numberOfSubmissions, shouldUnshiftArray, quota.variables))
      } else {
        setQuotaOptions(quota.options)
      }
    }
  }, [quota])

  return (
    <div className={quotaClassName} ref={quotaRef} id={`quota_${quotaId}`}>
      <h2 className="big mb-3 d-flex align-items-center flex-wrap gap-0-5">
        <div className="pr-4 mr-auto">Quota: {quota.name}</div>
        <div className="wrap_link pr-4">
          {quotaId === 'cintGender' && hasCensusData && !showCensusSource && (
            <button
              className="btn btn-default btn-sm back cancel clickable mr-0"
              onClick={reloadCensusData}
            >
              Reset to census
            </button>
          )}
          {quotaId !== genderFilterForCint.id && isQuotaOptionModified && (
            <button
              className="btn btn-default btn-sm back cancel clickable mr-0"
              onClick={handleResetQuota}
            >
              Reset
            </button>
          )}
        </div>
        <span className="float-right">
          <i className="ion-close" onClick={handleRemoveQuota}/>
        </span>
      </h2>

      <div className="help-text mb-3">
        <div className="po-text">{formattedQuestionText(quota)}</div>
        {(quotaId === 'cintGender' && hasCensusData) && (
          <div className="po-text">
            By default we will match the gender distribution of <span className="po-text-bold">{country_name}</span>
          </div>
        )}
      </div>

      {(isGenderCensusLoading && quotaId === 'cintGender') ? (
        <Spinner style={{scale: '0.8'}}/>
      ) : (
        <div className='scroll-wrapper'>
          <div className="quota-table mb-3">
            <table className="po-text">
              <tbody>
              <tr className="header text-dark">
                <td></td>
                <td>Percent</td>
                <td>Participants</td>
                <td className="pb-2">Feasible</td>
              </tr>
              {quotaOptions && quotaOptions.map(option =>
                <QuotaListItem
                  key={option.id}
                  quotaId={quotaId}
                  optionId={option.id}
                  numberOfSubmissions={numberOfSubmissions}
                  onOptionChange={handleOptionChange}
                  setShowCensusSource={setShowCensusSource}
                  setIsQuotaOptionModified={setIsQuotaOptionModified}
                />
              )}
              <QuotaTotal
                isTotalParticipantsInvalid={isTotalParticipantsInvalid}
                isTotalPercentInvalid={isTotalPercentInvalid}
                total={total}
                showSource={showCensusSource}
              />
              </tbody>
            </table>
            <QuotaError
              isTotalParticipantsInvalid={isTotalParticipantsInvalid}
              isTotalPercentInvalid={isTotalPercentInvalid}
              setActiveTab={setActiveTab}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default Quota
